import { Injectable } from '@angular/core';
import { map, shareReplay, catchError, retryWhen, take, delay } from 'rxjs/operators';
import { ClientApi, ApiException, UserSummary } from '../client-api.service';
import { ArrayUtil } from '../shared/array-util';
import { AuthService } from '@tcc/ui';
import { throwError, of, combineLatest, Observable } from 'rxjs';
import { UserInfo } from './user-info';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  readonly nameComparer = ArrayUtil.compareStringsFuncFactory({ ignoreCase: true });

  /** Gets all users */
  readonly allUsers$ = this.clientApi.getUsers().pipe(
    map(x => x.result.sort((a, b) => this.nameComparer(a.name, b.name))),
    shareReplay(1)
  );

  /** gets this current user, make sure to reload the user if there was a change in the user state */
  readonly currentUser$: Observable<UserInfo> = combineLatest([
    this.authSvc.authorizedAccount$,
    this.allUsers$
  ]).pipe(
    map(([msAccount, allUsers]) => {
      if (msAccount === undefined) {
        throw new Error();
      }

      const roles = this.extractRoleValues(msAccount.idTokenClaims!.roles!);
      const matchedUser = allUsers.find(user => user.objectId === msAccount.idTokenClaims.oid);
      const user: UserInfo = {
        userId: matchedUser.userId,
        email: msAccount.username,
        name: msAccount.name!,
        objectId: msAccount.idTokenClaims.oid,
        features: roles.features,
        orgCodes: roles.orgCodes,
        unknowns: roles.unknowns
      };

      // sync user with the database
      var userSummary: UserSummary = {
        userId: user.userId,
        email: user.email,
        name: user.name,
        objectId: user.objectId
      };
      this.clientApi.putUser(userSummary).subscribe();

      return user;
    }),
    catchError(err =>
      (ApiException.isApiException(err) && err.status === 401)
        ? of(undefined)
        : throwError(err)
    ),
    retryWhen(errors => errors.pipe(take(2), delay(500))), // 500 = 1/2 second
    shareReplay(1)
  );

  /** gets users mapped by id */
  readonly userMap$ = this.allUsers$.pipe(
    map(x => new Map(x.map(y => [y.userId, y]))),
    shareReplay(1)
  );

  constructor(private authSvc: AuthService, private clientApi: ClientApi) { }

  extractRoleValues(roles: string[]) {
    const roleTypes:  {[key: string]: string} = {
      feature: 'Feature',
      orgCode: 'OrgCode'
    };

    const userFeatures = [] as string[];
    const userOrgCodes = [] as string[];
    const userUnknowns = [] as string[];

    if (roles !== undefined && roles.length) {
      roles.forEach(role => {
        const splitRole = role.split('~~');
        const roleType = splitRole[0];
        const roleValue = splitRole[1];

        switch(roleType) {
          case roleTypes.feature:
            userFeatures.push(roleValue);
            break;
          case roleTypes.orgCode:
            userOrgCodes.push(roleValue);
            break;
          default:
            userUnknowns.push(roleValue);
        }
      });
    }
    return { features: userFeatures, orgCodes: userOrgCodes, unknowns: userUnknowns };
  }
}
