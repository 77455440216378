<tcc-simplePanel [size]="panelSize$ | async" [options]="{respondsToMainMenu: true}">
  <div panel-header>Home</div>
  <div panel-footer>
    <div class="d-flex w-100 flex-row ">
      <div class="flex-grow-1">
        <app-leases-pager [pageInfo]="pageInfo$ | async" (pageInfoChange)="pageInfoChanged($event)" ></app-leases-pager>
      </div>
      <div *ngIf="(pageInfo$ | async)?.totalItems" class="flex-shrink-1 ps-2">
        <button class="btn btn-sm btn-outline-primary" (click)="exportCsv()">
          <i class="fas fa-file-download"></i> Download
        </button>
      </div>
    </div>

  </div>
  <div panel-body class="d-flex flex-column h-100">
    <app-leases-filter (filterChange)="filterChanged($event)" class="above-sticky-top"></app-leases-filter>
    <table tccSorterHost (sorterStatesChange)="sortChanged($event)"
                     class="d-block flex-shrink-1" style="overflow-y: auto">
      <table class="table table-hover table-sm table-striped small">
        <thead class="thead-light">
          <tr>
            <th class="sticky-top text-nowrap">
              <tcc-sorter class="d-block" sorterTag="unitSpace" [sorterState]="true">
                Unit Space
              </tcc-sorter>
            </th>
            <th class="sticky-top text-nowrap">
              <tcc-sorter class="d-block" sorterTag="primaryContact" [sorterState]="true">
                Primary Resident
              </tcc-sorter>
            </th>
            <th *ngIf="(panelSize$ | async) === 'full'" class="d-sm-none d-lg-table-cell sticky-top text-nowrap">
              <tcc-sorter class="d-block" sorterTag="leaseEnd" [sorterState]="true">
                Lease End
              </tcc-sorter>
            </th>
            <th *ngIf="(panelSize$ | async) === 'full'" class="d-sm-none d-lg-table-cell sticky-top text-nowrap">
              <tcc-sorter class="d-block" sorterTag="leaseStatus" [sorterState]="true">
                Lease Status
              </tcc-sorter>
            </th>
            <th *ngIf="(panelSize$ | async) === 'full'" class="d-sm-none d-lg-table-cell sticky-top text-nowrap">
              <tcc-sorter class="d-block" sorterTag="currentOfferAmount" [sorterState]="true">
                Offer Amount
              </tcc-sorter>
            </th>
            <th class="sticky-top text-nowrap">
              <tcc-sorter class="d-block" sorterTag="ynmStatus" [sorterState]="true">
                <!-- when large we want to disambiguate status type from lease status, when small just show status-->
                <ng-container *ngIf="(panelSize$ | async) === 'full'; else smallStatus">
                  <span class="d-sm-none d-lg-inline"> Offer Status</span>
                  <span class="d-sm-inline d-lg-none"> Status</span>
                </ng-container>
                <ng-template #smallStatus>
                  Status
                </ng-template>
              </tcc-sorter>
            </th>
            <th class="sticky-top text-nowrap">
              <tcc-sorter class="d-block" sorterTag="lastContactEventOn" [sorterState]="true">
                Last Contact
              </tcc-sorter>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let l of (leases$ | async)"
              [routerLink]="[l.orgCode, l.externalLeaseId]"
              [ngClass]="{'table-warning': l.externalLeaseId === (selectedLeaseId$ | async) }">
            <td>{{l.unitSpace.spaceNumber}}</td>
            <td>{{l.primaryContact}}</td>
            <td *ngIf="(panelSize$ | async) === 'full'" class="d-sm-none d-lg-table-cell">
              {{l.leaseEnd | date: 'M/d/yyyy' }}
            </td>
            <td *ngIf="(panelSize$ | async) === 'full'" class="d-sm-none d-lg-table-cell">
              <app-selector [items]="leaseStatuses" [ngModel]="l.leaseStatus" [readonly]="true"></app-selector>
            </td>
            <td *ngIf="(panelSize$ | async) === 'full'" class="d-sm-none d-lg-table-cell">
              <ng-container *ngIf="l.currentOfferAmount; else noOffer">
                {{l.currentOfferAmount | currency : 'USD' : 'symbol' : '0.0-0' }}
              </ng-container>
              <ng-template #noOffer>
                &mdash;
              </ng-template>
            </td>
            <td>
              <app-selector [items]="ynmStatusItems" [ngModel]="l.ynmStatus" [readonly]="true"></app-selector>
            </td>
            <td>
              <ng-container *ngIf="l.latestEventCreatedOn; else neverContacted">{{l.latestEventCreatedOn | date: 'M/d/yyyy'}}</ng-container>
              <ng-template #neverContacted>Never</ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </table>
    <tcc-loadingOverlay *ngIf="(state$ | async) !== 'ready'" style="overflow:hidden"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>
<router-outlet></router-outlet>
