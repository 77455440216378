<!--The content below is only a placeholder and can be replaced.-->
<nav class="navbar navbar-fixed-top bg-gradient-collier text-white">

  <a class="navbar-brand">
    <img src="assets/logo.png" />
    Yes/No/Maybe
  </a>
  <div class="spinner-border text-light"
        style="width: 1.6rem; height: 1.6rem; vertical-align: middle;"
        *ngIf="(processingState$ | async) === 'processing';"
        role="status" text="Loading...">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-border text-danger"
        style="width: 1.6rem; height: 1.6rem; vertical-align: middle;"
        *ngIf="(processingState$ | async) === 'failed';"
        role="status" text="A process failed">
    <span class="sr-only">A process failed</span>
  </div>
  <tcc-user-status></tcc-user-status>
</nav>

<div class="contentPanel" #container>
  <simpleMenu [isMainMenu]="true" [isExpandable]="true">
    <button type="button" class="btn btn-sm btn-light" title="Home" routerLink="./leases" routerLinkActive="active">
      <i class="fas fa-home fa-fw"></i><span class="ms-1 visible-expanded">Home</span>
    </button>
    <button type="button" class="btn btn-sm btn-light" title="Create Offers" routerLink="./offers" routerLinkActive="active"
            *ngIf="offersNav()">
      <i class="fas fa-funnel-dollar fa-fw"></i><span class="ms-1 visible-expanded">Create Offers</span>
    </button>
    <button type="button" class="btn btn-sm btn-light" title="Managers Dashboard" routerLink="./dashboard" routerLinkActive="active">
      <i class="fas fa-chart-pie fa-fw"></i><span class="ms-1 visible-expanded">Managers Dashboard</span>
    </button>
  </simpleMenu>
  <router-outlet></router-outlet>
</div>
<tcc-preloader *ngIf="this.state !== 'ready'">Yes/No/Maybe</tcc-preloader>
<tcc-notifications [defaultDuration]="10000"></tcc-notifications>

