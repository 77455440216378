import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { ContactInfo } from '../client-api.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ArrayUtil } from '../shared/array-util';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactComponent),
      multi: true
    }
  ]
})
export class ContactComponent implements ControlValueAccessor {
  private static readonly nameComparer = (() => {
    const comparer = ArrayUtil.compareStringsFuncFactory({ ignoreCase: true });
    return (a: ContactInfo, b: ContactInfo) => comparer(a.name, b.name);
  })();

  // tslint:disable: member-ordering
  private onChange: (_: any) => void = () => { };
  private onTouch: () => void = () => { };

  itemMap = new Map<number, ContactInfo>();
  itemsVisible: ContactInfo[] = [];

  /** This will update the values of contacts, contactMap, and possibly value */
  @Input('items')
  set itemsSource(value: ContactInfo[]) {
    this.isItemsLoaded = value != null;
    const items = (Array.isArray(value)) ? value : [];
    this.itemsVisible = items.filter(x => !x.isHidden).sort(ContactComponent.nameComparer);
    this.itemMap = new Map(items.map(x => [x.externalId, x]));
    if (!this.itemMap.has(this.value)) {
      this.onValueChange(this.value);
    }
  }


  isDisabled = false;
  isItemsLoaded = false;

  @Input('readonly')
  isReadOnly = false;

  value: any;

  // tslint:emable: member-ordering
  constructor(private cd: ChangeDetectorRef) { }

  onValueChange(value: any) {
    if (value !== this.value) {
      this.value = value;
      this.onChange(value);
      this.onTouch();
    }
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cd.detectChanges();
  }

  writeValue(value: any) {
    this.value = (value == null) ? undefined : value;
    this.cd.detectChanges();
  }
}
