import { Injectable } from '@angular/core';
import { NotificationsService } from '@tcc/ui';
import { merge, of, Subject, combineLatest } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil, tap, throttleTime, take, map } from 'rxjs/operators';
import { AppListeningService } from './app-listening.service';
import { AppStateService } from './app-state.service';
import { EventsService } from './events/events.service';
import { LeasesService } from './leases/leases.service';
import { tapError } from './shared/tap-error-operator';
import { Idle } from '@ng-idle/core';
import { OfferManagementService } from './offer-management/offer-management.service';
import { OfferManagementStateService } from './offer-management/offer-management-state.service';

/** While the app is running this service will route service events to appState events. */
@Injectable({
  providedIn: 'root'
})
export class AppCoordinatorService {

  private stopSubject = new Subject();

  constructor(
    private appState: AppStateService,
    private eventsSvc: EventsService,
    private leasesSvc: LeasesService,
    private listeningSvc: AppListeningService,
    private idle: Idle,
    private notifySvc: NotificationsService,
    private offersStateSvc: OfferManagementStateService
  ) { }


  start() {
    this.idle.watch(true);

    this.idle.onIdleStart.pipe(
      tap(() => this.listeningSvc.stop()),
      takeUntil(this.stopSubject)
    ).subscribe();

    this.idle.onIdleEnd.pipe(
      tap(() => {
        this.listeningSvc.start();
        this.appState.refresh();
      }),
      takeUntil(this.stopSubject)
    ).subscribe();

    // if leaseNotification has occurred or events have changed then notify the application that the lease has changed
    merge(this.listeningSvc.leaseUpdate$, this.eventsSvc.eventDelete$, this.eventsSvc.eventUpdate$).pipe(
      throttleTime(250),
      tap(x => this.appState.leaseUpdated(x)),
      takeUntil(this.stopSubject)
    ).subscribe();

    // if a lease update event occurs, updates the current lease.
    this.appState.leaseUpdate$.pipe(
      throttleTime(250),
      filter(x => this.appState.leaseKeys && this.appState.leaseKeys.externalLeaseId === x.externalLeaseId),
      switchMap(({ externalLeaseId, orgCode }) => {
        this.appState.leaseDetailChanged(undefined);
        return this.loadLease(orgCode, externalLeaseId).pipe(
          // if the current lease changes then stop updating
          takeUntil(this.appState.leaseKeys$.pipe(filter(x => !x || x.externalLeaseId !== externalLeaseId))),
          tap(l => this.appState.leaseDetailChanged(l))
        );
      }),
      takeUntil(this.stopSubject)
    ).subscribe();

    // update lease detail when leaseKeys$ changes.
    merge(
      this.appState.leaseKeys$,
      this.appState.refresh$.pipe(map(() => this.appState.leaseKeys)) // If refreshing get current key value.
    ).pipe(
      tap(() => this.appState.leaseDetailChanged(undefined)), // immediately invalidate current lease before loading new lease
      filter(leaseKeys => !!leaseKeys), // only continue if lease keys have a value.
      switchMap(leaseKeys => this.loadLease(leaseKeys.orgCode, leaseKeys.externalLeaseId)),
      tap(x => this.appState.leaseDetailChanged(x)),
      takeUntil(this.stopSubject)
    ).subscribe();

    this.commandChangesListen();
  }

  stop() {
    this.idle.stop();
    this.stopSubject.next();
  }

  private loadLease(orgCode: string, externalLeaseId: number) {
    return this.leasesSvc.getLease(orgCode, externalLeaseId).pipe(
      tap(lease => {
        if (lease == null) {
          this.notifySvc.addWarning(
            'The Lease you are attempting to load was not found.  It is likely this lease is no longer a current lease.');
        }
      }),
      tapError(() => this.notifySvc.addError('Failed loading lease.  Please reload the application and try again.'))
    );
  }

  /** starts listing to command state changes and updates appState accordingly */
  private commandChangesListen() {
    // the intention is that in the future there will be more commands.
    const allCommandChanges = [
      this.offersStateSvc.addOffersCommands.commandStateChange$
    ];

    // listen to all comand changes.  If there is a failure then notify the appState
    merge(...allCommandChanges).pipe(
      filter(x => x.changedCommand.state === 'failed'),
      tap(() => this.appState.commandQueueFailureOccurred()),
      takeUntil(this.stopSubject)
    ).subscribe();

    combineLatest(allCommandChanges).pipe(
      tap(x => {
        const hasCommands = x.some(y => y.commandQueue.length !== 0);
        this.appState.commandQueueHasItemsChanged(hasCommands);
      }),
      takeUntil(this.stopSubject)
    ).subscribe();

  }
}
