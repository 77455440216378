import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardHostComponent } from './dashboard-host/dashboard-host.component';
import { EventFormComponent } from './events/event-form.component';
import { LeaseComponent } from './leases/lease.component';
import { LeaseGuard } from './leases/lease.guard';
import { LeasesComponent } from './leases/leases.component';
import { OrgGuard } from './orgs/org.guard';
import { EventGuard } from './events/event.guard';
import { OffersComponent } from './offer-management/offers.component';
import { MsalGuard } from '@azure/msal-angular';
import { OfferCreationGuard } from './guards/offer-creation.guard';


const routes: Routes = [
  { path: '', redirectTo: '/leases', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardHostComponent, canActivate: [MsalGuard], data: { title: 'Dashboard' } },
  { path: 'offers', component: OffersComponent, canActivate: [MsalGuard, OfferCreationGuard], data: { title: 'Offer Management' } },
  {
    path: 'leases',
    canActivate: [MsalGuard],
    component: LeasesComponent,
    data: { title: 'Leases' },
    children: [
      {
        path: ':orgCode/:externalLeaseId',
        canActivate: [MsalGuard, OrgGuard, LeaseGuard],
        canDeactivate: [LeaseGuard],
        component: LeaseComponent,
        data: { title: 'View Lease' },
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange', // OrgGuard and LeaseGuard need to always be reloaded when the route changes
        children: [
          {
            path: 'events/:eventId',
            canActivate: [MsalGuard, EventGuard],
            canDeactivate: [EventGuard],
            component: EventFormComponent,
            data: { title: 'Edit Event' },
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
