import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NotificationsService, SorterComponentChangeEvent, SubsManager } from '@tcc/ui';
import { BehaviorSubject, combineLatest, merge, of } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, filter, map, mapTo, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';
import { LeaseSummary } from '../client-api.service';
import { OrgsService } from '../orgs/orgs.service';
import { EnumUi } from '../shared/enum-ui';
import { tapError } from '../shared/tap-error-operator';
import { FilterInfo } from './leases-filter.component';
import { PaginationInfo } from './leases-pager.component';
import { LeasesService } from './leases.service';
import { SortUtil, SortInfo } from '../shared/sort-util';


@Component({
  selector: 'app-leases',
  templateUrl: './leases.component.html'
})
export class LeasesComponent implements OnDestroy, AfterViewInit {
  private exportStatusSubject = new BehaviorSubject<'loading' | 'none' | 'requested'>('none');
  private filterChangeSubject = new BehaviorSubject<FilterInfo>(undefined);
  private sortChangeSubject = new BehaviorSubject<SortInfo>({ sortCol: undefined, sortDir: undefined });
  private leasesLoadingSubject = new BehaviorSubject<('notloaded' | 'loading' | 'loaded')>('notloaded');
  private pageInfoSubject = new BehaviorSubject<PaginationInfo>({ pageIndex: 0, pageSize: 50, totalItems: 0 });


  private routeChange$ = this.router.events.pipe(
    filter(x => x instanceof NavigationEnd),
    mapTo(this.route),
    startWith(this.route)
  );

  /** gets leases */
  private readonly leaseResults$ = combineLatest([
    this.filterChangeSubject.pipe(debounceTime(250)),
    this.sortChangeSubject,
    // only update on pageIndex or size change
    this.pageInfoSubject.pipe(distinctUntilKeyChanged('pageIndex')),
    // Since we're using combineLatest, make sure refresh stream has an initial value.
    this.appStateSvc.refresh$.pipe(startWith(undefined as unknown))
  ]).pipe(
    debounceTime(10),
    filter(([f, s, p]) => !!(f && s && p)), // make sure filterInfo, sorterInfo, and pageInfo are defined
    tap(() => this.leasesLoadingSubject.next('loading')),
    switchMap(([f, s, p]) => {
      if (!f.orgCode) {
        return of({ leases: [] as LeaseSummary[], totalCount: 0, skip: p.pageSize });
      }
      return this.leasesSvc.getLeases(
        f.orgCode, f.leaseEndMin, f.leaseEndMax, f.ynmStatuses, f.leaseStatus, f.unitSpaceFilter,
        p.pageIndex * p.pageSize, p.pageSize, s.sortCol, s.sortDir === 'desc');
    }),
    tap(x => this.pageInfoSubject.next({ ... this.pageInfoSubject.value, totalItems: x.totalCount })),
    map(x => x.leases),
    tap(() => this.leasesLoadingSubject.next('loaded')),
    tapError(() => {
      this.notifySvc.addError('There was an error with loading leases.  Please refresh your browser and try again');
      this.leasesLoadingSubject.next('notloaded');
    }),
    shareReplay(1)
  );
  private readonly subsMgr = new SubsManager();

  readonly leaseStatuses = EnumUi.leaseStatuses;

  /** gets leases from leasesSvc, and updated leases when a lease is updated that's in the current list. */
  readonly leases$ = this.leasesSvc.trackLeaseUpdates(this.leaseResults$, this.appStateSvc.leaseUpdate$);


  readonly pageInfo$ = this.pageInfoSubject.pipe(shareReplay(1));

  /** The site of the panel - toggled by the presence of child routes. */
  readonly panelSize$ = this.routeChange$.pipe(
    map(r => r.children.length === 0 ? 'full' : 'large')
  );

  /** The currently selected leaseId.  */
  readonly selectedLeaseId$ = merge(
    this.appStateSvc.leaseKeys$.pipe(map(x => x != null ? x.externalLeaseId : undefined))
  );

  /** is the component ready to be interacted with? */
  readonly state$ = combineLatest([
    this.orgsSvc.orgs$.pipe(map(x => !!x), startWith(false)),
    this.leasesLoadingSubject.pipe(map(x => x !== 'loading')),
    this.exportStatusSubject.pipe(map(x => x === 'none'))
  ]).pipe(
    map(([orgsLoaded, leasesLoaded, exportWaiting]) => (orgsLoaded && leasesLoaded && exportWaiting) ? 'ready' : 'loading')
  );

  readonly ynmStatusItems = EnumUi.ynmStatuses;

  constructor(
    private appStateSvc: AppStateService,
    private leasesSvc: LeasesService,
    private notifySvc: NotificationsService,
    private orgsSvc: OrgsService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngAfterViewInit() {
    this.subsMgr.addSub = this.exportStatusSubject.pipe(
      filter(x => x === 'requested'),
      tap(() => this.exportStatusSubject.next('loading')),
      switchMap(() => {
        const res = this.filterChangeSubject.value;
        return this.leasesSvc.exportLeases(res.orgCode, res.leaseEndMin, res.leaseEndMax,
          res.ynmStatuses, res.leaseStatus, res.unitSpaceFilter);
      }),
      tapError(() => this.notifySvc.addError('Export failed.  Please refresh your browser and try again.')),
      tap(() => this.exportStatusSubject.next('none')),
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  exportCsv() {
    this.exportStatusSubject.next('requested');
  }

  filterChanged(filterInfo: FilterInfo) {
    this.filterChangeSubject.next(filterInfo);
  }

  pageInfoChanged(pageInfo: PaginationInfo) {
    this.pageInfoSubject.next(pageInfo);
  }

  sortChanged(states: SorterComponentChangeEvent[]) {
    this.sortChangeSubject.next(SortUtil.handleSingleItemSortChange(states));
  }

}
