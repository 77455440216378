import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferListComponent } from './offer-list.component';
import { OffersComponent } from './offers.component';
import { TccUiModule } from '@tcc/ui';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { LeasesModule } from '../leases/leases.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    OfferListComponent,
    OffersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LeasesModule,
    RouterModule,
    SharedModule,
    TccUiModule
  ],
  exports: [
    OffersComponent
  ]
})
export class OfferManagementModule {

}
