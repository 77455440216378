import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { from, Observable, Subject } from 'rxjs';
import { finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class AppListeningService {

  /** current hub connection */
  private hubConnection: HubConnection;
  /** notifications of lease updates */
  private leaseUpdateSubject = new Subject<{ externalLeaseId: number, orgCode: string }>();
  /** notification when we stop listening to hub */
  private stopSubject = new Subject();

  /** notifies when a lease has been updated */
  leaseUpdate$ = this.leaseUpdateSubject.asObservable();

  reconnectIntervalMs = 5000;
  reconnectMaxAttempts = 20;

  constructor() { }

  start() {
    if (this.hubConnection) {
      return;
    }

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.apiBaseUrl + '/apphub')
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) =>
          (retryContext.previousRetryCount < this.reconnectMaxAttempts) ? this.reconnectIntervalMs : null
      })
      .build();

    from(this.hubConnection.start()).pipe(
      mergeMap(() =>
        this.registerCallback<{ externalLeaseId: number, orgCode: string }>(this.hubConnection, 'LeaseUpdate').pipe(
          tap(x => this.leaseUpdateSubject.next(x))
        )
      ),
      takeUntil(this.stopSubject),
      finalize(() => {
        this.hubConnection.stop();
        this.hubConnection = undefined;
      })
    ).subscribe();
  }

  stop() {
    this.stopSubject.next();
  }

  /**
   * registers a callback to the connection
   * @param connection hubConnection. This is passed so that it is ensured that the event can properly be unregistered.
   * @param eventName the name of the event to register the callback for
   */
  private registerCallback<T>(connection: HubConnection, eventName: string) {
    return new Observable<T>(obs => {
      connection.on(eventName, val => obs.next(val));
      const unregisterSub = this.stopSubject.subscribe(() => {
        connection.off(eventName);
        obs.complete();
        unregisterSub.unsubscribe();
      });
    });
  }

}
