<tcc-simplePanel [size]="'full'" [options]="{respondsToMainMenu: true}">
  <div panel-header>Offers Management</div>
  <div panel-footer>
    <div class="d-flex w-100 flex-row align-items-baseline ">
      <div class="flex-grow-1 text-muted text-end">
        <em>
          {{(offerMgmtState.offers$ | async)?.length || 0 | number}} offers returned.
          <ng-container *ngIf="(offerMgmtState.selectedOffers$ | async).length as selectedOffersLength">
            ({{selectedOffersLength}} selected)
          </ng-container>
        </em>
      </div>
      <div class="flex-shrink-1 ps-2">
        <button class="btn btn-sm btn-outline-primary"
                [disabled]="!(offerMgmtState.selectedOffers$ | async).length"
                (click)="clearSelections()">
          Clear Selections
        </button>
      </div>
      <div class="flex-shrink-1 ps-2">
        <button class="btn btn-sm btn-outline-primary"
                [disabled]="!(offerMgmtState.comparisonType$ | async).length"
                (click)="toggleComparisonType()">
          Change Comparison Type
        </button>
      </div>
    </div>
  </div>
  <div panel-body class="d-flex flex-column h-100">
    <div class="d-flex flex-row flex-wrap above-sticky-top">
      <app-leases-filter (filterChange)="filterChanged($event)"></app-leases-filter>
      <div class="d-flex flex-row">
        <div class="form-group flex-shrink-0 me-3">
          <label>Group Units</label>
          <app-selector [items]="groupingItems" [ngModel]="offerMgmtState.groupBy$ | async"
                        (ngModelChange)="offerMgmtState.groupByChanged($event)">
          </app-selector>
        </div>
        <div class="form-group flex-shrink-0 me-3">
          <label>Aggregate By</label>
          <app-selector [items]="aggFuncTypes" [ngModel]="offerMgmtState.aggFunc$ | async"
                        (ngModelChange)="offerMgmtState.aggFuncChanged($event)">
          </app-selector>
        </div>
        <div class="form-group flex-shrink-0 me-3">
          <label>Offer Age (days)</label>
          <div class="form-inline flex-nowrap">
            <input type="number" class="form-control form-control-sm" style="width:5em" placeholder="min"
                   [ngModel]="(offerMgmtState.ageRange$ | async).min"
                   (ngModelChange)="ageRangeChanged(undefined, $event)" />
            -
            <input type="number" class="form-control form-control-sm" style="width:5em" placeholder="max"
                   [ngModel]="(offerMgmtState.ageRange$ | async).max"
                   (ngModelChange)="ageRangeChanged($event, undefined)" />
          </div>
        </div>
        <div class="form-group flex-shrink-0 me-3">
          <label>Bulk Update Operator</label>
          <div class="input-group input-group-sm">
            <select class="form-select" [(ngModel)]="bulkOperatorValue" (ngModelChange)="onBulkOperatorValueChange($event)">
              <option value="flatRateUpdate"> Flat Rate Update </option>
              <option value="incrementCurrentRateBy"> Increment Current Rent By </option>              
            </select>
          </div>
        </div>
        <div class="form-group flex-shrink-0 me-3">
          <label>Bulk Update Rates</label>
          <div class="input-group input-group-sm">
            <input #offerAmtInput type="number" class="form-control form-control-sm" style="width:5em"  />
            <div class="input-group-append">
              <button class="btn btn-danger btn-sm"
                      [disabled]="(offerMgmtState.selectedOffers$ | async).length === 0"
                      (click)="updateSelectedBulkOffers(offerAmtInput.value)">Go</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-offer-list style="overflow-y: auto"></app-offer-list>
    <tcc-loadingOverlay *ngIf="(state$ | async) != 'ready'" style="overflow:hidden"></tcc-loadingOverlay>
  </div>
</tcc-simplePanel>
<router-outlet></router-outlet>
