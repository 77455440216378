import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSpanFormat'
})
export class TimeSpanFormatPipe implements PipeTransform {
  /** The amount to multiply the time span to get the number of hours */
  private readonly hourFactor = 24;

  /** The maximum value where a number can still be represented as hours. */
  private readonly hourMaxThreshold = 23 / 24;

  /** The amount to multiply the time span to get the number of minutes */
  private readonly minuteFactor = 60 * 24;

  /** The maximum value where a number can still be represented as minutes. */
  private readonly minuteMaxThreshold = 59 / 60 / 24;

  /** The amount to multiply the time span to get the number of seconds */
  private readonly secondFactor = 60 * 60 * 24;

  /** The maximum value where a number can still be represented as seconds. */
  private readonly secondMaxThreshold = 59 / 60 / 60 / 24;

  private readonly zeroMaxThreshold = 1 / 60 / 60 / 24;

/** The amount to multiply the time space to get the number of minutes */

  transform(value: any, ...args: any[]): any {
    const zeroText = args[0];
    if (typeof value === 'number') {
      return this.formatTimeSpan(value, zeroText);
    }
    else if (typeof value === 'string') {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return this.formatTimeSpan(parsedValue, zeroText);
      }
    }
    return undefined;
  }

  /**
   * Returns string representation of timeSpan.
   * @param timeSpan
   */
  private formatTimeSpan(timeSpan: number, zeroText: string) {
    if (timeSpan > this.hourMaxThreshold) {
      return this.getFormattedText('day', timeSpan);
    }
    else if (timeSpan > this.minuteMaxThreshold) {
      return this.getFormattedText('hour', timeSpan * this.hourFactor);
    }
    else if (timeSpan > this.secondMaxThreshold) {
      return this.getFormattedText('minute', timeSpan * this.minuteFactor);
    }
    else if (!zeroText || timeSpan > this.zeroMaxThreshold) {
      return this.getFormattedText('second', timeSpan * this.secondFactor);
    }
    return zeroText;
  }

  private getFormattedText(timePart: string, value: number) {
    const roundedValue = Math.round(value);
    const pluralCorrectedTimePart = timePart + ((roundedValue !== 1) ? 's' : '');
    return `${roundedValue} ${pluralCorrectedTimePart} ago`;
  }
}
