import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaseComponent } from './lease.component';
import { LeasesComponent } from './leases.component';
import { LeasesFilterComponent } from './leases-filter.component';
import { LeasesPagerComponent } from './leases-pager.component';
import { SharedModule } from '../shared/shared.module';
import { OrgsModule } from '../orgs/orgs.module';
import { FormsModule } from '@angular/forms';
import { TccUiModule } from '@tcc/ui';
import { RouterModule } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { EventsModule } from '../events/events.module';



@NgModule({
  declarations: [
    LeaseComponent,
    LeasesComponent,
    LeasesFilterComponent,
    LeasesPagerComponent
  ],
  exports: [
    LeasesComponent,
    LeasesFilterComponent
  ],
  imports: [
    CommonModule,
    EventsModule,
    FormsModule,
    NgbPaginationModule,
    OrgsModule,
    RouterModule,
    SharedModule,
    TccUiModule
  ]
})
export class LeasesModule { }
