<div class="d-flex flex-row flex-grow-1 align-items-baseline"
     *ngIf="pageInfo.totalItems; else noItems">
  <div class="flex-grow-1">
    <ngb-pagination [page]="pageInfo.pageIndex + 1" (pageChange)="pageNumberChanged($event)"
                    [pageSize]="pageInfo.pageSize" size="sm" [maxSize]="5" [ellipses]="true"
                    [collectionSize]="pageInfo.totalItems"></ngb-pagination>
  </div>
  <div class="flex-shrink-1 text-muted">
    <em>{{pageInfo.totalItems}} Total Leases.</em>
  </div>
</div>
<ng-template #noItems>
  <div class="pt-1 pb-2 text-muted">
    <em>No leases returned. Please adjust the filters on top to get results.</em>
  </div>
</ng-template>
