<table tccSorterHost (sorterStatesChange)="offerMgmtState.sortChanged($event)">
  <table class="table table-hover table-sm table-striped small">
    <thead class="thead-light">
      <tr>
        <th class="sticky-top text-nowrap">
          <tcc-sorter class="d-block" sorterTag="unitSpace" [sorterState]="true">
            Unit Space
          </tcc-sorter>
        </th>
        <th class="sticky-top text-nowrap">
          <tcc-sorter class="d-block" sorterTag="primaryContact" [sorterState]="true">
            Primary Resident
          </tcc-sorter>
        </th>
        <th class="sticky-top text-nowrap">
          <tcc-sorter class="d-block" sorterTag="leaseEnd" [sorterState]="true">
            Lease End
          </tcc-sorter>
        </th>
        <th class="sticky-top text-nowrap">
          <tcc-sorter class="d-block" sorterTag="leaseStatus" [sorterState]="true">
            Lease Status
          </tcc-sorter>
        </th>
        <th class="sticky-top text-nowrap text-end">
          <tcc-sorter class="d-block" sorterTag="budgetedRent" [sorterState]="true">
            Budgeted Rent
          </tcc-sorter>
        </th>
        <th class="sticky-top text-nowrap text-end">
          <tcc-sorter class="d-block" sorterTag="leaseAmount" [sorterState]="true">
            Current Rent
          </tcc-sorter>
        </th>
        <th class="sticky-top text-nowrap text-end">
          <tcc-sorter class="d-block" sorterTag="newOfferAmount" [sorterState]="true">
            Offer Amount
          </tcc-sorter>
        </th>
        <ng-container *ngIf="(offerMgmtState.comparisonType$ | async) === 'percent'; else valueComparisonHeaders">
          <th class="sticky-top text-nowrap text-end">
            <tcc-sorter class="d-block" sorterTag="newOfferPctOfBudget" [sorterState]="true">
              % vs Budget
            </tcc-sorter>
          </th>
          <th class="sticky-top text-nowrap text-end">
            <tcc-sorter class="d-block" sorterTag="newOfferPctOfLeaseAmount" [sorterState]="true">
              % vs cur. Rent
            </tcc-sorter>
          </th>
        </ng-container>
        <ng-template #valueComparisonHeaders>
          <th class="sticky-top text-nowrap text-end">
            <tcc-sorter class="d-block" sorterTag="newOfferDiffFromBudget" [sorterState]="true">
              vs Budget
            </tcc-sorter>
          </th>
          <th class="sticky-top text-nowrap text-end">
            <tcc-sorter class="d-block" sorterTag="newOfferDiffFromLeaseAmount" [sorterState]="true">
              vs cur. Rent
            </tcc-sorter>
          </th>
        </ng-template>
        <th class="sticky-top text-nowrap text-end">
          <tcc-sorter class="d-block" sorterTag="currentOfferCreatedOn" [sorterState]="true">
            Offer Age
          </tcc-sorter>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let x of (offers$ | async); trackBy: getOfferTrackByKey">
        <ng-container [ngSwitch]="x.type">
          <ng-container *ngSwitchCase="'heading'">
            <tr class="table-primary fw-bold" (click)="toggleSelection(x.relatedOffers)">
              <td colspan="99">{{x.label}}</td>
            </tr>
          </ng-container>
          <ng-container *ngSwitchCase="'summary'">
            <tr class="table-secondary fw-bold" (click)="toggleSelection(x.relatedOffers)">
              <td colspan="4">
                {{x.label}}
                <app-selector [items]="aggFuncTypes" [ngModel]="offerMgmtState.aggFunc$ | async" readonly="true"></app-selector>
              </td>
              <td class="text-end">{{getAggregateValue(x.relatedOffers, 'unitSpace.budgetedRent') | currency : 'USD' : 'symbol' : '0.0-0'}}</td>
              <td class="text-end">{{getAggregateValue(x.relatedOffers, 'leaseAmount') | currency : 'USD' : 'symbol' : '0.0-0'}}</td>
              <td class="text-end">{{getAggregateValue(x.relatedOffers, 'currentOfferAmount') | currency : 'USD' : 'symbol' : '0.0-0'}}</td>
              <ng-container *ngIf="(offerMgmtState.comparisonType$ | async) === 'percent'; else valueComparisonFooters">
                <td class="text-end">{{gePctOfBudgetedRentAggregate(x.relatedOffers) | percent : '0.1-1' }}</td>
                <td class="text-end">{{gePctOfLeaseAmountAggregate(x.relatedOffers) | percent : '0.1-1' }}</td>
              </ng-container>
              <ng-template #valueComparisonFooters>
                <td class="text-end">{{getAggregateValue(x.relatedOffers, 'newOfferDiffFromBudget') | currency : 'USD' : 'symbol' : '0.0-0'}}</td>
                <td class="text-end">{{getAggregateValue(x.relatedOffers, 'newOfferDiffFromLeaseAmount') | currency : 'USD' : 'symbol' : '0.0-0'}}</td>
              </ng-template>
              <td></td>
            </tr>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="offerRow; context: { $implicit: x.offer }"></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</table>
<ng-template #offerRow let-x>
  <tr [ngClass]="{ 'font-italic': x.currentOfferAmount != x.newOfferAmount, 'table-warning': selectedLeaseIds.has(x.externalLeaseId) }"
      (click)="toggleSelection(x)">
    <td>
      {{x.unitSpace.spaceNumber}}
      <a [href]="x.externalLeaseUrl" target="_blank" title="View Lease in Entrata" tabindex="2">
        <sup><i class="fas fa-external-link-square-alt"></i></sup>
      </a>
    </td>
    <td>{{x.primaryContact}}</td>
    <td>
      {{x.leaseEnd | date: 'M/d/yyyy' }}
    </td>
    <td>
      <app-selector [items]="leaseStatuses" [ngModel]="x.leaseStatus" [readonly]="true"></app-selector>
    </td>
    <ng-container *ngTemplateOutlet="currencyCell; context: { $implicit: x.unitSpace?.budgetedRent }"></ng-container>
    <ng-container *ngTemplateOutlet="currencyCell; context: { $implicit: x.leaseAmount }"></ng-container>
    <td class="py-0 align-middle text-end" (click)="$event.stopPropagation()">
      <input #thisInput="ngModel"
             type="text"
             class="input-subtle bg-light text-end"
             style="width: 5rem"
             [ngModel]="x.newOfferAmount | number: '0.0-5'"
             (ngModelChange)="thisInput.valid ? updateOffer(x, $event) : undefined"
             (focus)="$event.target.select();"
             (blur)="offerBlurSubject.next()"
             pattern="^\s*\$?\s*(\+|-)?((\d{1,3},?)+(\.\d*)?|\.\d+)\s*$"
             [ngClass]="{'is-invalid': thisInput.invalid, 'bg-light': !thisInput.invalid }"
             [title]="'New Offer Amount for ' + x.unitSpace.spaceNumber"
             tabindex="1" />
    </td>
    <ng-container *ngIf="(offerMgmtState.comparisonType$ | async) === 'percent'; else valueComparisonValues">
      <ng-container *ngTemplateOutlet="percentCell; context: { $implicit: x.newOfferPctOfBudget }"></ng-container>
      <ng-container *ngTemplateOutlet="percentCell; context: { $implicit: x.newOfferPctOfLeaseAmount }"></ng-container>
    </ng-container>
    <ng-template #valueComparisonValues>
      <ng-container *ngTemplateOutlet="currencyCell; context: { $implicit: x.newOfferDiffFromBudget }"></ng-container>
      <ng-container *ngTemplateOutlet="currencyCell; context: { $implicit: x.newOfferDiffFromLeaseAmount }"></ng-container>
    </ng-template>
    <td class="text-end">
      <ng-container *ngIf="x.offerAge != null; else noOffer">
        {{x.offerAge | timeSpanFormat : 'New'}}
      </ng-container>
      <ng-template #noOffer>
        &mdash;
      </ng-template>
    </td>
  </tr>
</ng-template>
<ng-template #currencyCell let-x>
  <td class="text-end">
    <ng-container *ngIf="x != null; else noValue">
      {{x | currency : 'USD' : 'symbol' : '0.0-0'}}
    </ng-container>
    <ng-template #noValue>
      &mdash;
    </ng-template>
  </td>
</ng-template>
<ng-template #percentCell let-x>
  <td class="text-end">
    <ng-container *ngIf="x != null; else noValue">
      {{x | percent : '0.1-1' }}
    </ng-container>
    <ng-template #noValue>
      &mdash;
    </ng-template>
  </td>
</ng-template>
