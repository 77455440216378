import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

/** Methods to help resolve app specific params. */
@Injectable({
  providedIn: 'root'
})
export class AppRouteParamsService {
  private readonly externalLeaseIdParam = 'externalLeaseId';
  private readonly eventIdParam = 'eventId';
  private readonly orgCodeParam = 'orgCode';

  constructor() { }

  getExternalLeaseId(route: ActivatedRouteSnapshot) {
    return this.getIntParam(route, this.externalLeaseIdParam);
  }

  getEventId(route: ActivatedRouteSnapshot) {
    const rawValue = this.getStringParam(route, this.eventIdParam);
    if (rawValue != null) {
      if (rawValue === 'new') {
        return 'new';
      }
      else {
        const value = parseInt(rawValue, 10);
        return (!isNaN(value)) ? value : undefined;
      }
    }
    return undefined;
  }

  getOrgCode(route: ActivatedRouteSnapshot) {
    return this.getStringParam(route, this.orgCodeParam);
  }

  private getIntParam(route: ActivatedRouteSnapshot, paramName: string) {
    if (route && route.paramMap.has(paramName)) {
      const value = parseInt(route.paramMap.get(paramName), 10);
      if (!isNaN(value)) {
        return value;
      }
    }
    return undefined;
  }

  private getStringParam(route: ActivatedRouteSnapshot, paramName: string) {
    return (route) ? route.paramMap.get(paramName) : undefined;
  }
}
