import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { SubsManager } from '@tcc/ui';
import { combineLatest, race, Subject, timer } from 'rxjs';
import { debounce, map, shareReplay, tap } from 'rxjs/operators';
import { LeasesService } from '../leases/leases.service';
import { AggregateUtil } from '../shared/aggregate-util';
import { EnumUi } from '../shared/enum-ui';
import { ObservableSet } from '../shared/observable-set';
import { SortInfo } from '../shared/sort-util';
import { GroupingKey, OfferAgeRange, OfferModel } from './models';
import { OfferBatcherService } from './offer-batcher.service';
import { OfferManagementStateService } from './offer-management-state.service';
import { OfferManagementService } from './offer-management.service';

export interface OfferRow {
  /** optional label for headers and footers. */
  label?: string;
  /** the associated offer for an individual item. */
  offer?: OfferModel;
  /** related offers for headers and footers. */
  relatedOffers?: OfferModel[];
  /** the type of row. */
  type: 'heading' | 'item' | 'summary';
}


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html'
})
export class OfferListComponent implements OnInit, OnDestroy {
  private readonly offerAmountChangeSubject = new Subject<{ offer: OfferModel, offerAmountText: string }>();
  private readonly subsMgr = new SubsManager();

  readonly aggFuncTypes = EnumUi.aggTypes;

  readonly leaseStatuses = EnumUi.leaseStatuses;

  readonly offerBlurSubject = new Subject();

  /** these are the offers that should be used in the UI */
  readonly offers$ = combineLatest([
    this.offerMgmtState.offers$,
    this.offerMgmtState.groupBy$,
    this.offerMgmtState.ageRange$,
    this.offerMgmtState.sort$,
  ]).pipe(
    map(([offers, groupBy, offerAgeRange, sortInfo]) =>
      this.mapOffersToViewRows(offers, groupBy, offerAgeRange, sortInfo)),
    shareReplay(1),
    tap(() => this.cd.detectChanges())
  );

  readonly selectedLeaseIds = new ObservableSet<number>();

  constructor(private cd: ChangeDetectorRef,
    private offerBatcher: OfferBatcherService,
    public offerMgmtState: OfferManagementStateService,
    private offerMgmtSvc: OfferManagementService
  ) { }

  ngOnInit() {
    /* the following two streams are a thing of beauty.  The first debounces input changes the second batches changes. */
    this.subsMgr.addSub = this.offerAmountChangeSubject.pipe(
      // debounce after X ms or when blur subject fires, whatever comes first.
      debounce(() => race(
        timer(500),
        this.offerBlurSubject
      )),
      tap(({ offer, offerAmountText }) => {
        offer.newOfferAmount = parseInt(offerAmountText,10);
        this.offerMgmtSvc.updateOfferFields(offer);
        this.offerBatcher.enqueueOfferChanges(offer);
        this.cd.detectChanges();
      })
    ).subscribe();

    this.subsMgr.addSub = combineLatest([this.selectedLeaseIds.setChange$, this.offers$]).pipe(
      map(([set, offerRows]) => offerRows.filter(x => x.offer && set.includes(x.offer.externalLeaseId)).map(x => x.offer)),
      tap(x => this.offerMgmtState.selectedOffersChanged(x))
    ).subscribe();

    this.subsMgr.addSub = this.offerMgmtState.selectedOffers$.pipe(
      tap(x => {
        this.selectedLeaseIds.set(x.map(y => y.externalLeaseId));
        this.cd.detectChanges();
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  getOfferTrackByKey(_: number, offerRow: OfferRow) {
    return offerRow.type + (offerRow.label || offerRow.offer.externalLeaseId);
  }

  /**
   * performs aggregate function
   * @param offers A collection of offers
   * @param pathOrFunc either a path that is to be parsed to get the value, or a function to get the path.
   */
  getAggregateValue(offers: OfferModel[], pathOrFunc: string | ((OfferModel) => number)) {

    function getValueResolver(pathOrFuncInner: string | ((OfferModel) => number)) {
      if (typeof pathOrFuncInner === 'string') {
        const pathParts = pathOrFuncInner.split('.');
        return (x: OfferModel) => pathParts.reduce((acc, cur) => acc != null ? acc[cur] : undefined, x as any);
      }
      else {
        return pathOrFuncInner;
      }
    }

    const valueResolver = getValueResolver(pathOrFunc);
    const values = offers.map(valueResolver);
    return AggregateUtil.execute(this.offerMgmtState.aggFunc, values, { skipBlanks: true });

  }

  gePctOfBudgetedRentAggregate(offers: OfferModel[]) {
    if (this.offerMgmtState.aggFunc !== 'avg') {
      return AggregateUtil.execute(this.offerMgmtState.aggFunc, offers.map(x => x.newOfferPctOfBudget));
    }
    const items = offers.filter(x => x.newOfferPctOfBudget)
      .map(x => ({ value: x.newOfferPctOfBudget, weight: x.unitSpace && x.unitSpace.budgetedRent }));
    return AggregateUtil.weightedAvg(items);
  }

  gePctOfLeaseAmountAggregate(offers: OfferModel[]) {
    if (this.offerMgmtState.aggFunc !== 'avg') {
      return AggregateUtil.execute(this.offerMgmtState.aggFunc, offers.map(x => x.newOfferPctOfLeaseAmount));
    }
    const items = offers.filter(x => x.newOfferPctOfLeaseAmount)
      .map(x => ({ value: x.newOfferPctOfLeaseAmount, weight: x.leaseAmount }));
    return AggregateUtil.weightedAvg(items);
  }


  toggleSelection(offerOrOffers: OfferModel | OfferModel[]) {
    const externalLeaseIds = (Array.isArray(offerOrOffers) ? offerOrOffers : [offerOrOffers]).map(x => x.externalLeaseId);
    this.selectedLeaseIds.toggle(externalLeaseIds);
  }

  /** batches offers for update. */
  updateOffer(offer: OfferModel, offerAmountText: string) {
    this.offerAmountChangeSubject.next({ offer, offerAmountText });
  }

  private mapOffersToViewRows(
    offers: OfferModel[], groupBy: GroupingKey, offerAgeRange: OfferAgeRange, sortInfo: SortInfo) {

    const grouping = new Map<string, OfferModel[]>();

    if (!isNaN(offerAgeRange.max) || !isNaN(offerAgeRange.min)) {
      offers = offers.filter(x => (isNaN(offerAgeRange.min) || offerAgeRange.min <= x.offerAge)
        && (isNaN(offerAgeRange.max) || offerAgeRange.max >= x.offerAge));
    }

    switch (groupBy) {
      case 'UnitType':
        offers.forEach(x =>
          (!grouping.has(x.unitSpace.unitType))
            ? grouping.set(x.unitSpace.unitType, [x])
            : grouping.get(x.unitSpace.unitType).push(x)
        );
        break;
      default:
        grouping.set('All', offers);
        break;
    }

    const sortFunc = (sortInfo.sortCol)
      ? LeasesService.createCompareFunc(sortInfo.sortCol, sortInfo.sortDir === 'desc')
      : undefined;

    const offerRows: { label?: string, offer?: OfferModel, relatedOffers?: OfferModel[], type: 'heading' | 'item' | 'summary' }[] = [];
    for (const [label, grpOffers] of grouping.entries()) {
      if (sortFunc) {
        grpOffers.sort(sortFunc);
      }
      offerRows.push({ label, type: 'heading', relatedOffers: grpOffers });
      offerRows.push(...grpOffers.map(offer => ({ offer, type: 'item' as 'item' })));
      offerRows.push({ label, type: 'summary', relatedOffers: grpOffers });
    }

    return offerRows;
  }

}
