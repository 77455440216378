import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SubsManager } from '@tcc/ui';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { Organization } from '../client-api.service';
import { OrgsService } from './orgs.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-org-selector',
  templateUrl: './org-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrgSelectorComponent),
      multi: true
    }
  ]
})
export class OrgSelectorComponent implements OnDestroy, OnInit, ControlValueAccessor {
  private onChange: (_: any) => void = () => { };
  private onTouch: () => void = () => { };

  private subsMgr = new SubsManager();
  private valueWrittenSubject = new BehaviorSubject<void>(undefined);
  
  isDisabled = false;
  state: 'loading' | 'ready';

  @Input('readonly')
  isReadOnly = false;

  @Input()
  noSelectionLabel = ' --select one -- ';

  @Input()
  noSelectionReadOnlyLabel = 'None';

  @Input()
  value: any;

  @Output()
  readonly orgChange = new EventEmitter<Organization>();

  readonly orgs$ = this.orgsSvc.orgs$.pipe(
    tap(orgs => { if(orgs !== undefined) this.state = 'ready';})
  );
  
  readonly orgMap$ = this.orgsSvc.orgLookup$;

  constructor(private cd: ChangeDetectorRef, private orgsSvc: OrgsService) {
    this.state = 'loading';
  }

  ngOnInit() {
    this.subsMgr.addSub = combineLatest([this.valueWrittenSubject, this.orgs$]).pipe(
      debounceTime(0),
      tap(([_, orgs]) => this.syncItemsAndValue(orgs))
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  onValueChange(value: any) {

    if (value !== this.value) {
      this.value = value;
      this.onChange(value);
      this.onTouch();
    }
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cd.detectChanges();
  }

  writeValue(value: any) {
    this.value = (value == null) ? undefined : value;
    this.valueWrittenSubject.next();
  }

  /**
   * Keeps the value and selection items in sync.
   */
  private syncItemsAndValue(items: Organization[]) {
    if (items) {
      let newItemId: string;
      if (items.length === 1) {
        newItemId = items[0].orgCode;
      }
      else if (this.value != null) {
        newItemId = items.some(x => x.orgCode === this.value) ? this.value : undefined;
      }
      this.cd.detectChanges();
      this.onValueChange(newItemId);
    }
  }
}
