import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppStateService } from '../app-state.service';
import { AppRouteParamsService } from '../shared/app-route-params.service';
import { LeasesService } from './leases.service';

/** Loads and Unloads Leases during Activation and Deactivation.
 * Unlike other guards, this updates the appState with the keys.
 * This will cause the route to load immediately and the component is responsible for ensuring the leaseDetail is loaded.
 */
@Injectable({
  providedIn: 'root'
})
export class LeaseGuard implements CanActivate, CanDeactivate<any> {

  constructor(
    private appState: AppStateService, private appRouteParamsSvc: AppRouteParamsService, private leasesSvc: LeasesService) { }

  canActivate(
    next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const externalLeaseId = this.appRouteParamsSvc.getExternalLeaseId(next);
    const orgCode = this.appRouteParamsSvc.getOrgCode(next);


    if (orgCode && externalLeaseId) {
      this.appState.leaseKeysChanged({ externalLeaseId, orgCode });
      return true;
    }
    else {
      this.appState.leaseKeysChanged(undefined);
      return false;
    }
  }

  canDeactivate(_: any, __: ActivatedRouteSnapshot, ___: RouterStateSnapshot) {
    this.appState.leaseKeysChanged(undefined);
    return true;
  }
}
