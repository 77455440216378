import { formatCurrency, formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { covertArrayToFlatFile, CSV_FORMAT, FileDownloadService } from '@tcc/ui';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ApiException, ClientApi, LeaseDetail, LeaseStatus, LeaseSummary, YnmStatus } from '../client-api.service';
import { PagedResponseHeaders } from '../shared/paged-response-headers';
import { retryDelay } from '../shared/retry-delay-operator';

@Injectable({
  providedIn: 'root'
})
export class LeasesService {

  /**
   * Createa a comparison function that can be used when sorting.
   */
  static createCompareFunc(
    col: 'budgetedRent' | 'currentOfferAmount' | 'currentOfferCreatedOn' | 'leaseAmount' | 'leaseEnd' | 'leaseStatus' | 'primaryContact'
      | 'unitSpace' | string,
    reverseResult: boolean
  ) {

    const reversalFactor = (!reverseResult) ? 1 : -1;
    let valueFunc: (x: LeaseSummary) => number | string;
    switch (col) {
      case 'budgetedRent': valueFunc = x => x.unitSpace.budgetedRent; break;
      case 'currentOfferAmount': valueFunc = x => x.currentOfferAmount; break;
      case 'currentOfferCreatedOn':
        valueFunc = x => x.currentOfferCreatedOn instanceof Date ? x.currentOfferCreatedOn.getTime() : 0;
        break;
      case 'leaseAmount': valueFunc = x => x.leaseAmount; break;
      case 'leaseEnd': valueFunc = x => x.leaseEnd instanceof Date ? x.leaseEnd.getTime() : 0; break;
      case 'leaseStatus': valueFunc = x => x.leaseStatus; break;
      case 'primaryContact': valueFunc = x => x.primaryContact ? x.primaryContact.toLowerCase() : ''; break;
      case 'unitSpace': valueFunc = x => x.unitSpace.spaceNumber ? x.unitSpace.spaceNumber.toLowerCase() : ''; break;
      default: valueFunc = x => x[col];
    }
    return (a: LeaseSummary, b: LeaseSummary) => {
      const valA = valueFunc(a);
      const valB = valueFunc(b);
      return ((valA < valB) ? -1 : (valA > valB) ? 1 : 0) * reversalFactor;
    };
  }

  private readonly leaseExportMappings: ({ header: string, mapping: (x: LeaseDetail) => string })[] = [
    { header: 'Lease Interval Id', mapping: x => x.externalLeaseId.toString() },
    { header: 'Unit Type', mapping: x => x.unitSpace.unitType },
    { header: 'Unit Space', mapping: x => x.unitSpace.spaceNumber },
    { header: 'Primary Resident', mapping: x => x.primaryContact },
    { header: 'Lease End', mapping: x => x.leaseEnd ? formatDate(x.leaseEnd, 'M/d/yyyy', this.locale) : '' },
    { header: 'Lease Status', mapping: x => x.leaseStatus },
    { header: 'Rent', mapping: x => x.leaseAmount > 0 ? formatCurrency(x.leaseAmount, this.locale, '$') : '' },
    { header: 'Offer Amount', mapping: x => x.currentOfferAmount > 0 ? formatCurrency(x.currentOfferAmount, this.locale, '$') : '' },
    { header: 'Offer Status', mapping: x => x.ynmStatus },
    { header: 'Last Contact', mapping: x => x.latestEventCreatedOn ? formatDate(x.latestEventCreatedOn, 'M/d/yyyy', this.locale) : '' },
  ];

  constructor(
    private clientApi: ClientApi,
    private fileDlSvc: FileDownloadService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  exportLeases(
    orgCode: string, leaseEndMin: Date, leaseEndMax: Date, ynmStatuses: YnmStatus[], leaseStatus: LeaseStatus, unitSpaceFilter: string) {

    return this.getLeases(orgCode, leaseEndMin, leaseEndMax, ynmStatuses, leaseStatus, unitSpaceFilter, 0, 9999, undefined, false).pipe(
      retryDelay(2, x => 5000 * (x + 1)),
      tap(res => {
        const header = this.leaseExportMappings.map(x => x.header);
        const body = res.leases.map(x => this.leaseExportMappings.map(y => y.mapping(x)));
        const rawText = covertArrayToFlatFile([header].concat(body), CSV_FORMAT);
        this.fileDlSvc.startRawDownload('YNM export.csv', rawText, 'text/csv');
      })
    );
  }

  /**
   * Returns a lease matching orgCode and externalLeaseId.  If no lease is found then undefined is returned.
   */
  getLease(orgCode: string, externalLeaseId: number) {
    return this.clientApi.getLeaseById(orgCode, externalLeaseId)
      .pipe(
        map((x) => x.result),
        // don't throw an error if it is a 404
        catchError(err => {
          if (ApiException.isApiException(err)) {
            if (err.status === 404) {
              return of<LeaseDetail>(undefined);
            }
          }
          return throwError(err);
        }),
        retryDelay(2, x => 5000 * (x + 1))
      );
  }

  getLeases(
    orgCode: string, leaseEndMin: Date, leaseEndMax: Date, ynmStatuses: YnmStatus[], leaseStatus: LeaseStatus, unitSpaceFilter: string,
    skip: number, top: number, orderBy: string, orderDescending: boolean) {

    const orderByExpr = (orderBy) ? `${orderBy} ${orderDescending ? 'desc' : ''}`.trim() : undefined;
    return this.clientApi
      .getLeases(orgCode, leaseEndMin, leaseEndMax, ynmStatuses, leaseStatus, unitSpaceFilter, skip, top, orderByExpr, true)
      .pipe(
        retryDelay(2, x => 5000 * (x + 1)),
        map(x => ({
          totalCount: parseInt((x.headers as PagedResponseHeaders).$count, 10) || 0,
          skip,
          leases: x.result
        }))
      );
  }

  /**
   * Creates an observable that will keep the leases from leaseSource$ updated if an update comes in from updateSource$ with a matching id.
   * @param leasesSource$
   * @param updateSource$
   */
  trackLeaseUpdates(leasesSource$: Observable<LeaseSummary[]>, updateSource$: Observable<{ externalLeaseId: number, orgCode: string }>) {
    return leasesSource$.pipe(
      switchMap(leases => {
        return updateSource$.pipe(
          map(leaseKeys => ({ leaseKeys, matchIdx: leases.findIndex(x => x.externalLeaseId === leaseKeys.externalLeaseId) })),
          filter(({ matchIdx }) => matchIdx !== -1),
          switchMap(({ leaseKeys, matchIdx }) =>
            this.getLease(leaseKeys.orgCode, leaseKeys.externalLeaseId).pipe(map(lease => ({ lease, matchIdx })))
          ),
          map(({ lease, matchIdx }) => {
            // if no lease then remove from leases collection, else replace at matchIndex
            (!lease) ? leases.splice(matchIdx, 1) : leases[matchIdx] = lease;
            return leases;
          }),
          startWith(leases) // return original leasesSource$ after original emmission.
        );
      })
    );
  }
}
