import { MonoTypeOperatorFunction, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * behaves like tap but for errors.
 */
export function tapError<T>(action?: (err: any, caught: Observable<T>) => any): MonoTypeOperatorFunction<T>  {
  return (source$: Observable<T>) => {
    return source$.pipe(catchError((err, caught) => {
      action(err, caught);
      return throwError(err);
    }));
  };
}
