import { YnmStatus, YnmResult, LeaseStatus } from '../client-api.service';
import { SelectorItem } from './selector.component';
import { AggregationType } from './aggregate-util';

export class EnumUi {
  // tslint:disable: max-line-length
  /** combination of YnmStatus with YnmResult */
  private static readonly mergedYnmStatuses = [
    { ynmStatus: YnmStatus.NotContacted, label: 'Not Contacted', iconCssClass: 'fas fa-fw text-secondary fa-sad-tear', ynmResult: YnmResult.NoContact },
    { ynmStatus: YnmStatus.NoResponse, label: `Didn't Respond`, iconCssClass: 'far fa-fw text-muted fa-meh-blank', ynmResult: YnmResult.NoResult },
    { ynmStatus: YnmStatus.Maybe, label: 'Maybe', iconCssClass: 'far fa-fw text-info fa-surprise', ynmResult: YnmResult.Maybe },
    { ynmStatus: YnmStatus.No, label: 'No', iconCssClass: 'fas fa-fw text-danger fa-thumbs-down', ynmResult: YnmResult.No },
    { ynmStatus: YnmStatus.Yes, label: 'Yes', iconCssClass: 'fas fa-fw text-success fa-thumbs-up', ynmResult: YnmResult.Yes },
    { ynmStatus: YnmStatus.LeaseApproved, label: 'Lease Approved', iconCssClass: 'fas fa-fw text-primary fa-file-signature', ynmResult: undefined }
  ];
  // tslint:enable: max-line-length

  static readonly aggTypes: SelectorItem<AggregationType>[] = [
    { value: 'avg', label: 'Average' },
    { value: 'max', label: 'Max' },
    { value: 'min', label: 'Min' },
    { value: 'sum', label: 'Sum' },
  ];

  static readonly leaseStatuses: SelectorItem<LeaseStatus>[] = [
    { value: undefined, label: 'All Statuses' },
    { value: LeaseStatus.Current, label: 'Current' },
    { value: LeaseStatus.Notice, label: 'Notice' },
    { value: LeaseStatus.Unknown, label: 'Unknown' },
  ];

  static readonly ynmStatuses: SelectorItem<YnmStatus>[] = EnumUi.mergedYnmStatuses
    .map(x => ({ value: x.ynmStatus, label: x.label, iconCss: x.iconCssClass }));

  static readonly ynmResults: SelectorItem<YnmResult>[] = EnumUi.mergedYnmStatuses.filter(x => x.ynmResult)
    .map(x => ({ value: x.ynmResult, label: x.label, iconCss: x.iconCssClass }));

}
