<div class="d-flex flex-row pb-2 flex-wrap">
  <div class="d-flex flex-row">
    <div class="form-group flex-shrink-0  me-3">
      <label>Community</label>
      <app-org-selector [ngModel]="filter.orgCode" (ngModelChange)="filter.orgCode = $event; filterChanged()"></app-org-selector>
    </div>
    <div class="form-group flex-shrink-0 me-3">
      <label>Offer Status</label>
      <app-selector [items]="ynmStatusItems" [ngModel]="filter.ynmStatuses" [multiple]="true"
                    [noSelectionLabel]="{ value: 'All Statuses', iconCss: 'fas fa-fw text-info fa-globe' }"
                    [multipleSelectionsLabel]="{ value: 'Multiple Selections' }"
                    (ngModelChange)="filter.ynmStatuses = $event; filterChanged()">
      </app-selector>
    </div>
    <div class="form-group flex-shrink-0 me-3" style="width: 5rem">
      <label>Unit Space</label>
      <input type="text" class="form-control form-control-sm" [ngModel]="filter.unitSpaceFilter"
             (ngModelChange)="filter.unitSpaceFilter = $event; filterChanged()" />
    </div>
  </div>
  <div class="d-flex flex-row">
    <div class="form-group flex-shrink-0 me-3">
      <label>Lease End Min</label>
      <input type="date" class="form-control form-control-sm" useValueAsDate
             style="width:10rem"
             [ngModel]="filter.leaseEndMin"
             (ngModelChange)="filter.leaseEndMin = $event; filterChanged()" />
    </div>
    <div class="form-group flex-shrink-0 me-3">
      <label>Lease End Max</label>
      <input type="date" class="form-control form-control-sm" useValueAsDate
             style="width:10rem"
             [ngModel]="filter.leaseEndMax"
             (ngModelChange)="filter.leaseEndMax = $event; filterChanged()" />
    </div>
    <div class="form-group flex-shrink-0 me-3">
      <label>Lease Status</label>
      <app-selector [items]="leaseStatuses"
                    [ngModel]="filter.leaseStatus"
                    (ngModelChange)="filter.leaseStatus = $event; filterChanged()">
      </app-selector>
    </div>
  </div>
</div>
