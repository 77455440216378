import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';
import { AppRouteParamsService } from '../shared/app-route-params.service';
import { OrgsService } from './orgs.service';
import { NotificationsService } from '@tcc/ui';
import { tapError } from '../shared/tap-error-operator';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class OrgGuard implements CanActivate {

  constructor(
    private appState: AppStateService,
    private appRouteParamsSvc: AppRouteParamsService,
    private notifySvc: NotificationsService,
    private orgsSvc: OrgsService,
    private usersService: UsersService) { }

  canActivate(
    next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const orgCode = this.appRouteParamsSvc.getOrgCode(next);

    return combineLatest([
      this.orgsSvc.orgLookup$,
      this.usersService.currentUser$
    ]).pipe(
      map(([orgMap, user]) => {
        const org = orgMap.get(orgCode);
        this.appState.orgCurrentChanged(org);
        return org !== undefined && user.orgCodes.includes(org.orgCode);
      }),
      tapError(() => this.notifySvc.addError('Failed loading Organization.  Please refresh the application and try again.'))
    )
  }
}
