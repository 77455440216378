import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';
import { LeaseSummary } from '../client-api.service';
import { FilterInfo } from '../leases/leases-filter.component';
import { LeasesService } from '../leases/leases.service';
import { OrgsService } from '../orgs/orgs.service';
import { EnumUi } from '../shared/enum-ui';
import { SelectorItem } from '../shared/selector.component';
import { tapError } from '../shared/tap-error-operator';
import { OfferBatcherService } from './offer-batcher.service';
import { OfferManagementStateService } from './offer-management-state.service';
import { OfferManagementService } from './offer-management.service';
import { GroupingKey, OfferModel } from './models';



@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-offers',
  templateUrl: './offers.component.html'
})
export class OffersComponent implements OnInit, OnDestroy {
  private readonly subsMgr = new SubsManager();
  private readonly filterChangeSubject = new BehaviorSubject<FilterInfo>(undefined);
  private readonly leasesLoadingSubject = new BehaviorSubject<('notloaded' | 'loading' | 'loaded')>('notloaded');

  bulkOperatorValue: 'flatRateUpdate' | 'incrementCurrentRateBy';
  
  /** gets leases */
  readonly leaseResults$ = combineLatest([
    this.filterChangeSubject.pipe(debounceTime(250)),
    // Since we're using combineLatest, make sure refresh stream has an initial value.
    this.appStateSvc.refresh$.pipe(startWith(undefined as unknown))
  ]).pipe(
    debounceTime(10),
    filter(([f]) => !!f), // make sure filterInfo is defined
    tap(() => this.leasesLoadingSubject.next('loading')),
    switchMap(([f]) => {
      if (!f.orgCode) {
        return of({ leases: [] as LeaseSummary[], totalCount: 0, skip: 0 });
      }
      // unlike with leases, sorting is done on client side and there is no paging.
      return this.leasesSvc.getLeases(
        f.orgCode, f.leaseEndMin, f.leaseEndMax, f.ynmStatuses, f.leaseStatus, f.unitSpaceFilter,
        0, undefined, undefined, undefined);
    }),
    map(x => x.leases),
    tap(() => this.leasesLoadingSubject.next('loaded')),
    tapError(() => {
      this.notifySvc.addError('There was an error with loading leases.  Please refresh your browser and try again');
      this.leasesLoadingSubject.next('notloaded');
    }),
    shareReplay(1)
  );

  readonly aggFuncTypes = EnumUi.aggTypes;

  readonly groupingItems: SelectorItem<GroupingKey>[] = [
    { label: 'Unit Type', value: 'UnitType' },
    { value: 'None' }
  ];

  readonly leaseStatuses = EnumUi.leaseStatuses;


  /** is the component ready to be interacted with? */
  readonly state$ = combineLatest([
    this.orgsSvc.orgs$.pipe(map(x => !!x), startWith(false)),
    this.leasesLoadingSubject.pipe(map(x => x !== 'loading')),
  ]).pipe(map(([orgsLoaded, leasesLoaded]) => (orgsLoaded && leasesLoaded) ? 'ready' : 'loading'));

  readonly ynmStatusItems = EnumUi.ynmStatuses;


  constructor(
    private appStateSvc: AppStateService,
    private leasesSvc: LeasesService,
    private notifySvc: NotificationsService,
    private offerBatcher: OfferBatcherService,
    public offerMgmtState: OfferManagementStateService,
    private offerMgmtSvc: OfferManagementService,
    private orgsSvc: OrgsService
  ) {
    this.bulkOperatorValue = 'flatRateUpdate';

  }

  ngOnInit() {

    this.subsMgr.addSub = this.leaseResults$.pipe(
      map(x => this.offerMgmtSvc.mapLeasesToOffers(x)),
      switchMap(offers =>
        this.offerMgmtState.addOffersCommands.commandStateChange$.pipe(
          filter(x => x.changedCommand.state === 'completed'),
          map(x => {
            const offerCreatedOn = new Date();
            const batchResult = x.changedCommand.data.result;
            const hasChanges = batchResult.items.some(y => y.isSuccess);
            if (!batchResult.isSuccess) {
              const msg = hasChanges
                ? 'Some offers failed while saving.  Please refresh and try again.'
                : 'Unable to save changes.  Please refresh and try again.';
              this.notifySvc.addError(msg);
            }

            for (const res of batchResult.items) {
              if (res.isSuccess) {
                // replace any successful results
                const offerIndex = offers.findIndex(o => o.externalLeaseId === res.item.externalLeaseId);
                if (offerIndex !== -1) {
                  offers[offerIndex] = this.offerMgmtSvc.updateOfferFields({
                    ...offers[offerIndex],
                    currentOfferAmount: res.item.offerAmount,
                    currentOfferCreatedOn: offerCreatedOn,
                    newOfferAmount: res.item.offerAmount,
                  });
                }
              }
            }
            return { offers, hasChanges };
          }),
          filter(({ hasChanges }) => hasChanges),
          map((x) => x.offers),
          startWith(offers)
        )
      ),
      tap(x => this.offerMgmtState.offersChanged(x))
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  /**
 * Updates the offer age range.  If any argument is undefined it is ignored, while being set to null will having the value cleared.
 */
  ageRangeChanged(max: number | null | undefined, min: number | null | undefined) {
    const prevValue = this.offerMgmtState.ageRange;
    const ageRange = {
      max: max === undefined ? prevValue.max : (max === null ? undefined : max),
      min: min === undefined ? prevValue.min : (min === null ? undefined : min)
    };
    if (prevValue.max !== ageRange.max || prevValue.min !== ageRange.min) {
      this.offerMgmtState.ageRangeChanged(ageRange);
    }
  }

  clearSelections() {
    this.offerMgmtState.selectedOffersChanged([]);
  }

  filterChanged(filterInfo: FilterInfo) {
    this.filterChangeSubject.next(filterInfo);
  }

  toggleComparisonType() {
    const comparisonType = (this.offerMgmtState.comparisonType !== 'percent') ? 'percent' : 'value';
    this.offerMgmtState.comparisonTypeChanged(comparisonType);
  }

  onBulkOperatorValueChange(event: any) {

  }

  updateSelectedBulkOffers(offerAmountText: string) {
    
    switch(this.bulkOperatorValue) {
      case 'flatRateUpdate':
        for (const offer of this.offerMgmtState.selectedOffers) {
          offer.newOfferAmount = Math.round(parseInt(offerAmountText, 10));
          this.offerMgmtSvc.updateOfferFields(offer);
        }
        break;
      case 'incrementCurrentRateBy':
        for (const offer of this.offerMgmtState.selectedOffers) {
          offer.newOfferAmount = Math.round((offer.leaseAmount + parseInt(offerAmountText, 10)));
          this.offerMgmtSvc.updateOfferFields(offer);
        }

        break;
      default:
        return;        
    }

    this.offerBatcher.enqueueOfferChanges(this.offerMgmtState.selectedOffers);
  }
}
