import { Injectable } from '@angular/core';
import { NotificationsService } from '@tcc/ui';
import { BehaviorSubject } from 'rxjs';
import { concatMap, debounceTime, filter, tap } from 'rxjs/operators';
import { CommandInfo } from '../shared/command-state';
import { NumberParsingUtil } from '../shared/number-parsing-util';
import { tapError } from '../shared/tap-error-operator';
import { OfferBatchCommandData, OfferModel, OrgOfferBatchItem } from './models';
import { OfferManagementStateService } from './offer-management-state.service';
import { OfferManagementService } from './offer-management.service';

@Injectable({
  providedIn: 'root'
})
export class OfferBatcherService {
  private readonly offerBatchSubject = new BehaviorSubject<CommandInfo<OfferBatchCommandData>>(undefined);

  /** Number of milliseconds to wait until a batch should start executing. */
  executionDelay = 4000;

  constructor(private notifySvc: NotificationsService,
    private offerMgmtState: OfferManagementStateService,
    private offerMgmtSvc: OfferManagementService) {

    const addOffersCommands = offerMgmtState.addOffersCommands;

    this.offerBatchSubject.pipe(
      filter(command => command !== undefined), // do nothing if there is no batch.
      debounceTime(4000), // allow the command to build up by waiting up to 4 seconds.
      tap(command => {
        this.offerBatchSubject.next(undefined);
        addOffersCommands.setState(command, 'executing');
      }),
      concatMap(command => {
        return this.offerMgmtSvc.addOffers(command.data.items).pipe(
          tap(result => addOffersCommands.setState(command, 'completed', undefined, { items: command.data.items, result })),
          tapError(() => addOffersCommands.setState(command, 'failed', 'Failed updated offers batch'))
        );
      }),
    ).subscribe();
  }

  /** batches offers for update. */
  enqueueOfferChanges(offerOrOffers: OfferModel | OfferModel[]) {

    const offers = Array.isArray(offerOrOffers) ? offerOrOffers : [offerOrOffers];
    for (const offer of offers) {
      // make sure offer amounts are in sync.
      this.offerMgmtSvc.updateOfferFields(offer);
      const offerBatchItem: OrgOfferBatchItem = {
        externalLeaseId: offer.externalLeaseId,
        offerAmount: offer.newOfferAmount,
        orgCode: offer.orgCode
      };
      this.enqueueOrgBatchItem(offerBatchItem);
    }
  }

  /** Adds an offer to be saved in a batch */
  enqueueOrgBatchItem(offer: OrgOfferBatchItem) {
    const offerCommand = this.offerBatchSubject.value || this.offerMgmtState.addOffersCommands.enqueue({ items: [] });
    const items = offerCommand.data.items;

    // replace any offers with matching externalLeaseId or add new item if it doesn't already exist.
    const existingOfferIndex = items.findIndex(x => x.externalLeaseId === offer.externalLeaseId);
    (existingOfferIndex !== -1) ? items[existingOfferIndex] = offer : items.push(offer);

    this.offerBatchSubject.next(offerCommand);
  }
}
