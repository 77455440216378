import { SorterComponentChangeEvent } from '@tcc/ui';

/** Single column sort info */
export interface SortInfo {
  /** the column being sorted */
  sortCol?: string;
  /** the direction of the sort. */
  sortDir?: 'asc' | 'desc';
}

export class SortUtil {
  /** handles sort change event when only one column should be sorted at a time. */
  static handleSingleItemSortChange(states: SorterComponentChangeEvent[]) {
    const sorted = states.filter(x => x.state === 'asc' || x.state === 'desc')
      .map(x => ({ col: x.tag, dir: x.state as 'asc' | 'desc' }));

    const sortInfo: SortInfo = (!sorted.length)
      ? { sortCol: undefined, sortDir: undefined }
      : { sortCol: sorted[0].col, sortDir: sorted[0].dir };

    return sortInfo;
  }
}
