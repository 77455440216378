import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorComponent } from './selector.component';
import { DateValueAccessorDirective } from './date-value-accessor.directive';
import { TimeSpanFormatPipe } from './time-span-format.pipe';
import { ValidityClassesDirective } from './validity-classes.directive';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    DateValueAccessorDirective,
    SelectorComponent,
    TimeSpanFormatPipe,
    ValidityClassesDirective,
  ],
  exports: [
    DateValueAccessorDirective,
    SelectorComponent,
    TimeSpanFormatPipe,
    ValidityClassesDirective
  ],
  imports: [
    CommonModule,
    NgbDropdownModule
  ]
})
export class SharedModule { }
