import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RouterEvent } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TransitionStateService {
  private transitionSource = new Subject<TransitionState>();
  private urlAtNavigationStart: string;

  readonly transition$: Observable<TransitionState>;


  constructor(private router: Router) {
    this.transition$ = this.transitionSource.asObservable();

    this.router.events
      .subscribe(e => {
        if (e instanceof NavigationStart) {
          this.urlAtNavigationStart = this.router.url;
          this.transitionSource.next({ isTransitioning: true, fromRoute: this.urlAtNavigationStart, toRoute: e.url, sourceEvent: e });
        }
        else if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
          this.transitionSource.next({ isTransitioning: false, fromRoute: this.urlAtNavigationStart, toRoute: e.url, sourceEvent: e });
          this.urlAtNavigationStart = undefined;
        }
      });
  }
}

export interface TransitionState {
  isTransitioning: boolean;
  fromRoute: string;
  toRoute: string;
  sourceEvent: RouterEvent;
}
