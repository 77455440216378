<form (ngSubmit)="submitEvent()" [formGroup]="eventForm">
  <tcc-simplePanel size="small" [buttons]="['close']" (buttonClick)="closeComponent()">
    <div panel-header>
      <ng-container [ngSwitch]="mode$ | async">
        <ng-template [ngSwitchCase]="'add'">Add Event</ng-template>
        <ng-template [ngSwitchCase]="'edit'">Edit Event #{{(event$ | async).eventId}}</ng-template>
        <ng-template [ngSwitchCase]="'view'">View Event #{{(event$ | async).eventId}}</ng-template>
        <ng-template [ngSwitchCase]="'unknown'">Loading Event</ng-template>
      </ng-container>
    </div>
    <div panel-body class="d-flex flex-column h-100">
      <ng-container *ngIf="(isExisting$ | async)">
        <ng-container *ngTemplateOutlet="creationInfo; context: { $implicit: (event$ | async) }"></ng-container>
      </ng-container>

      <div class="form-group">
        <label>Event Type</label>
        <div [ngClass]="{ 'text-muted': (isReadOnly$ | async) }">
          <app-selector [items]="eventTypeUi$ | async"
                        formControlName="eventTypeId"
                        tccValidityClasses="is-invalid"
                        [readonly]="(isReadOnly$ | async)">
          </app-selector>
        </div>
      </div>
      <div class="form-group">
        <label>Contact</label>
        <div [ngClass]="{ 'text-muted': (isReadOnly$ | async) }">
          <app-contact formControlName="contactExternalId"
                       [items]="(lease$ | async)?.contacts"
                       [readonly]="(isReadOnly$ | async)"
                       tccValidityClasses="is-invalid"></app-contact>
        </div>
      </div>
      <div class="form-group">
        <label>Event Result</label>
        <div [ngClass]="{ 'text-muted': (isReadOnly$ | async) }">
          <app-selector [items]="ynmResults"
                        formControlName="ynmResult"
                        tccValidityClasses="is-invalid"
                        [readonly]="(isReadOnly$ | async)"></app-selector>
        </div>
      </div>
      <div class="form-group" [hidden]="eventForm.controls.moveOutReasonId.disabled">
        <label>Reason for Moving Out</label>
        <div [ngClass]="{ 'text-muted': (isReadOnly$ | async) }">
          <app-move-out-reason formControlName="moveOutReasonId"
                               [items]="(moveOutReasons$ | async)"
                               tccValidityClasses="is-invalid"
                               [readonly]="(isReadOnly$ | async)"></app-move-out-reason>
        </div>
      </div>
      <div class="form-group flex-grow-1 pb-2">
        <label>Notes</label>
        <textarea class="form-control form-control-sm h-100"
                  formControlName="description"
                  style="min-height: 4em"
                  *ngIf="(isReadOnly$ | async) === false; else readOnlyText"></textarea>
        <ng-template #readOnlyText>
          <div *ngIf="(event$ | async)?.description; else noNotes"
               class="bg-white py-2 px-2 my-1 border rounded-sm">
            <div style="white-space: pre-wrap;">{{(event$ | async).description}}</div>
          </div>
          <ng-template #noNotes>
            <div class="font-italic text-muted">No notes were recorded.</div>
          </ng-template>
        </ng-template>
      </div>
      <tcc-loadingOverlay *ngIf="(state$ | async) != 'ready'"></tcc-loadingOverlay>
    </div>
    <ng-container panel-footer>
      <div class="d-flex flex-row">
        <div class="flex-grow-1" >
          <div *ngIf="(isReadOnly$ | async) === false">
            <div class="btn-group btn-group-sm">
              <button type="submit" class="btn btn-primary">
                <i class="fas fa-save"></i> Save Event
              </button>
              <!-- <button type="button" class="btn btn-danger" (click)="deleteEvent()" *ngIf="(mode$ | async) == 'edit'">
                <i class="fas fa-trash"></i> Delete Event
              </button> -->
            </div>
          </div>
        </div>
        <div>
          <div class="btn-group btn-group-sm bg-light" *ngIf="(pageInfo$ | async).isVisible">
            <button type="button" class="btn btn-outline-primary bg-white" title="Previous Event"
                    [disabled]="!(pageInfo$ | async).prevEventId"
                    [routerLink]="['..', (pageInfo$ | async).prevEventId]">
              «
            </button>
            <button type="button" class="btn btn-outline-primary bg-white" title="Next Event"
                    [disabled]="!(pageInfo$ | async).nextEventId"
                    [routerLink]="['..', (pageInfo$ | async).nextEventId]">
              »
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </tcc-simplePanel>
</form>
<ng-template #creationInfo let-evt>
  <div class="d-flex flex-row">
    <div class="flex-grow-1 form-group">
      <label>Created By</label>
      <div class="text-muted">
        {{evt.creatorName || 'Unknown'}}
      </div>
    </div>
    <div class="flex-grow-1 form-group">
      <label>Created On</label>
      <div class="text-muted">
        {{evt.createdOn | date : 'short'}}
      </div>
    </div>
  </div>
</ng-template>
