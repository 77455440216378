import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';
import { LeaseSummary } from '../client-api.service';
import { EnumUi } from '../shared/enum-ui';

@Component({
  selector: 'app-lease',
  templateUrl: './lease.component.html'
})
export class LeaseComponent  {

  readonly lease$ = this.appState.leaseDetail$;

  readonly leaseStatuses = EnumUi.leaseStatuses;

  readonly org$ = this.appState.org$;

  readonly state$ = combineLatest([this.lease$, this.org$]).pipe(
    map(([l, o]) => ((l && o) ? 'ready' : 'loading') as ('loading' | 'ready')),
    startWith('loading' as 'loading')
  );

  readonly ynmStatusItems = EnumUi.ynmStatuses;

  constructor(
    private appState: AppStateService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  closeComponent() {
    this.router.navigate(['./'], { relativeTo: this.route.parent });
  }

  getDaysRemaining(l: LeaseSummary) {
    const diff = l.leaseEnd.valueOf() - Date.now();
    return Math.max(0, Math.ceil(diff / (1000 * 3600 * 24)));
  }
}
