import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ClientApi, EventInfo } from '../client-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private eventDeleteSubject = new Subject<{ eventId: number, externalLeaseId: number, orgCode: string }>();
  private eventUpdateSubject = new Subject<{ eventId: number, externalLeaseId: number, orgCode: string }>();

  readonly eventDelete$ = this.eventDeleteSubject.asObservable();

  readonly eventTypes$ = this.clientApi.getEventTypes().pipe(
    map(x => x.result.sort(y => y.sortIndex)),
    shareReplay(1)
  );

  /** Maps all event type ids to eventTpyes */
  readonly eventTypeMap$ = this.eventTypes$.pipe(
    map(x => new Map(x.map(y => [y.eventTypeId, y]))),
    shareReplay(1)
  );

  readonly eventTypeUi$ = this.eventTypes$.pipe(
    map(x => x.map(y => ({ value: y.eventTypeId, label: y.name, iconCss: `fas fa-${y.icon}` }))),
    shareReplay(1)
  );

  readonly eventUpdate$ = this.eventUpdateSubject.asObservable();

  readonly moveOutReason$ = this.clientApi.getMoveOutReasons().pipe(
    map(x => x.result.sort(y => y.sortIndex)),
    shareReplay(1)
  );

  readonly moveOutReasonMap$ = this.moveOutReason$.pipe(
    map(x => new Map(x.map(y => [y.reasonId, y]))),
    shareReplay(1)
  );

  env = environment;

  constructor( private clientApi: ClientApi) {

  }

  /** Adds or updates an event.  If successful returns stream with updated object. */
  upsertEvent(orgCode: string, externalLeaseId: number, eventInfo: EventInfo) {
    return this.clientApi.postEvent(orgCode, externalLeaseId, this.env.userInfo.userId, eventInfo).pipe(
      map(x => x.result),
      tap(evt => this.eventUpdateSubject.next({ eventId: evt.eventId, externalLeaseId, orgCode }))
    );
  }
}
