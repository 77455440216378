import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgSelectorComponent } from './org-selector.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { TccUiModule } from '@tcc/ui';


@NgModule({
  declarations: [OrgSelectorComponent],
  exports: [OrgSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TccUiModule
  ]
})
export class OrgsModule { }
