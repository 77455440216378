import { Injectable } from '@angular/core';
import { delay, map, retryWhen, shareReplay, take } from 'rxjs/operators';
import { ClientApi, Organization } from '../client-api.service';
import { ArrayUtil } from '../shared/array-util';
import { combineLatest } from 'rxjs';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class OrgsService {
  private readonly orgNameComparer = (() => {
    const comparer = ArrayUtil.compareStringsFuncFactory({ ignoreCase: true, ignoreArticles: true });
    return (a: Organization, b: Organization) => comparer(a.name, b.name);
  })();

  /** gets orgs */
  readonly orgs$ = combineLatest([
    this.clientApi.getOrganizations(),
    this.usersService.currentUser$
  ]).pipe(
    map(([orgs, user]) => {
      if (orgs === undefined || user === undefined) {
        throw new Error();
      }
      // filter out the orgs based on the Env's UserInfo orgs and then sort the result
      return orgs.result.filter(org => user.orgCodes.includes(org.orgCode)).sort(this.orgNameComparer);
    }),
    retryWhen(errors => errors.pipe(take(2), delay(500))), // 500 = 1/2 second
  );

  readonly orgLookup$ = this.orgs$.pipe(
    map(orgs => (orgs !== undefined) ?
                new Map(orgs.map(org => [org.orgCode, org]))
                : new Map<string, Organization>([['undefined', {}]])),
    shareReplay(1)
  );

  constructor(private clientApi: ClientApi, private usersService: UsersService) { }
}
