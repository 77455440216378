import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export interface PaginationInfo {
  pageIndex: number;
  pageSize: number;
  totalItems: number;
}

@Component({
  selector: 'app-leases-pager',
  templateUrl: './leases-pager.component.html'
})
export class LeasesPagerComponent implements OnInit {
  /** backing var for pageInfo property */
  private _pageInfo = this.createNewPaginationInfo();

  constructor() { }

  @Input()
  get pageInfo() { return this._pageInfo; }
  set pageInfo(value: PaginationInfo) {
    this._pageInfo = value ? { ...value } : this.createNewPaginationInfo();
  }

  @Output()
  readonly pageInfoChange = new EventEmitter<PaginationInfo>();

  ngOnInit() {
  }

  /**  Call when pageNumber is changed by ngbPagination. */
  pageNumberChanged(pageNumber: number) {
    this.pageInfo.pageIndex = pageNumber - 1;
    this.pageInfoChange.emit({ ...this.pageInfo });
  }

  private createNewPaginationInfo() {
    return <PaginationInfo>{
      pageIndex: 0,
      pageSize: 50,
      totalItems: 0
    };
  }
}
