<tcc-simplePanel size="small" [buttons]="['close']" (buttonClick)="closeComponent()">
  <div panel-header>
    <ng-container *ngTemplateOutlet="headerTemplt; context: { lease: lease$ | async }"></ng-container>
  </div>
  <div panel-body class="d-flex flex-column h-100">
    <ng-container *ngIf="(state$ | async) === 'ready'; else notReady">
      <ng-container *ngTemplateOutlet="bodyTmplt; context: { lease: lease$ | async }"></ng-container>
    </ng-container>
    <ng-template #notReady>
      <tcc-loadingOverlay></tcc-loadingOverlay>
    </ng-template>
  </div>
</tcc-simplePanel>
<!-- HEADER -->
<ng-template #headerTemplt let-l="lease">
  <ng-container *ngIf="l; else noLease">
    Lease Interval
    <a [href]="l.externalLeaseUrl" target="_blank" title="View Lease in Entrata">
      #{{l.externalLeaseId}}
      <sup><i class="fas fa-external-link-square-alt"></i></sup>
    </a>
  </ng-container>
  <ng-template #noLease>
    Lease Loading...
  </ng-template>
</ng-template>
<!-- BODY -->
<ng-template #bodyTmplt let-l="lease">

  <div class="row mb-3">
    <div class="col">
      <div class="pb-1">Unit Space</div>
      <div class="text-muted">{{l.unitSpace.building}} {{l.unitSpace.spaceNumber}}</div>
    </div>
    <div class="col-7">
      <div class="pb-1">Community</div>
      <div class="text-muted">
        <app-org-selector [ngModel]="l.orgCode" [readonly]="true"></app-org-selector>
      </div>
    </div>
  </div>

  <div class="h6 mb-1">Lease</div>
  <div class="row mb-3">
    <div class="col">
      <div class="pb-1">Primary Contact</div>
      <div class="text-muted">
        {{l.primaryContact}}
      </div>
    </div>
    <div class="col-3">
      <div class="pb-1">Rate</div>
      <div class="text-muted">{{l.leaseAmount | currency : 'USD' : 'symbol' : '0.0-0'}}</div>
    </div>
    <div class="col-4" [title]="getDaysRemaining(l) + ' days until lease end.'">
      <div class="pb-1">End Date</div>
      <div class="text-muted">
        {{l.leaseEnd | date : 'M/d/yyyy'}}
      </div>
    </div>
  </div>
  <div class="h6 mb-1">Offer</div>
  <div class="row mb-3">
    <div class="col-5">
      <div class="pb-1">Status</div>
      <div class="text-muted">
        <app-selector [items]="ynmStatusItems" [ngModel]="l.ynmStatus" [readonly]="true"></app-selector>
      </div>
    </div>
    <div class="col">
      <div class="pb-1">Amount</div>
      <div class="text-muted">
        <ng-container ng-container *ngIf="l.currentOfferAmount">
          {{
l.currentOfferAmount | currency : 'USD' : 'symbol' : '0.0-0'
          }}
        </ng-container>
        <ng-container ng-container *ngIf="!l.currentOfferAmount">
          {{'No Offer Created'}}
        </ng-container>
      </div>
    </div>

    <ng-container ng-container *ngIf="l.currentOfferAmount">
      <div class="col-4">
        <div class="pb-1">Created</div>
        <div class="text-muted">{{l.currentOfferCreatedOn | date: 'MM/dd/yyyy'}}</div>
      </div>
    </ng-container>
  </div>

  <div class="mb-1 d-flex flex-row">
    <div class="h5 flex-grow-1 mb-1 align-self-end">Event History</div>
    <button type="button" class=" btn btn-sm btn-primary" [routerLink]="['events', 'new']">
      <i class="fas fa-plus"></i>
      Add Event
    </button>
  </div>

  <div class="flex-shrink-1" style="overflow-y:auto; margin: 0 -6px">
    <app-event-list></app-event-list>
  </div>
</ng-template>
<router-outlet></router-outlet>
