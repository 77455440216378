<ng-container *ngIf="isReadOnly; else dropDown">
  <ng-container *ngIf="!value; else hasItem">Unknown</ng-container>
  <ng-template #hasItem>
    <ng-container *ngIf="!isItemsLoaded; else itemsLoaded">Loading ...</ng-container>
  </ng-template>
  <ng-template #itemsLoaded>
    <ng-container *ngIf="itemMap.has(value); else notFound">
      {{itemMap.get(value).name}}
    </ng-container>
  </ng-template>
  <ng-template #notFound>
    Contact Not Found
  </ng-template>
</ng-container>
<ng-template #dropDown>
  <div class="input-group input-group-sm">
    <select class="form-select" [ngModel]="value" (ngModelChange)="onValueChange($event)">
      <option [ngValue]="undefined"> -- select one -- </option>
      <option *ngFor="let opt of itemsVisible" [ngValue]="opt.externalId">{{opt.name}}</option>
    </select>
    <div class="input-group-append">
      <ng-container *ngTemplateOutlet="contactInfoBtn; context: { $implicit: itemMap.get(value) }"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #contactInfoBtn let-c>
  <button class="btn btn-secondary" type="button" [disabled]="!c" title="View Contact Details"
          [ngbPopover]="popContentCur" [popoverTitle]="popTitleCur" placement="bottom-right">
    <i class="fas fa-address-card"></i>
  </button>
  <ng-template #popTitleCur>
    <ng-container *ngTemplateOutlet="popTitle; context: { $implicit: c }"></ng-container>
  </ng-template>
  <ng-template #popContentCur>
    <ng-container *ngTemplateOutlet="popContent; context: { $implicit: c }"></ng-container>
  </ng-template>
</ng-template>
<ng-template #popTitle let-c>Details for {{c?.name || 'Unknown'}}</ng-template>
<ng-template #popContent let-c>
  <div>Relationship <span class="text-secondary">{{c?.relationship || 'Unknown'}}</span></div>
  <div>
    Phone
    <a *ngIf="c?.phoneNumber; else noPhone" [href]="'tel:' + c.phoneNumber">{{c.phoneNumber}}</a>
    <ng-template #noPhone><span class="text-secondary">None</span></ng-template>
  </div>
  <div>
    Email
    <a *ngIf="c?.email; else noEmail" [href]="'mailto:' + c.email">{{c.email}}</a>
    <ng-template #noEmail><span class="text-secondary">None</span></ng-template>
  </div>
</ng-template>
