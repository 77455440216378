/// This needs to be updated!!!
export const environment = {
  apiBaseUrl: '',
  appInsightsId: '',
  production: true,
  userInfo: {
    userId: 0,
    email: '',
    name: '',
    objectId: '',
    features: [''],
    orgCodes: [''],
    unknowns: ['']
  }
};
