<ng-container *ngIf="isReadOnly; else dropDown">
  <ng-container *ngTemplateOutlet="itemFromObject; context: { $implicit: getHeadingLabel(false) }"></ng-container>
</ng-container>
<ng-template #dropDown>
  <div ngbDropdown [autoClose]="isMultiple ? 'outside' : true" class="w-100">
    <button type="button" class="form-control form-control-sm text-start d-flex align-items-center"
            ngbDropdownToggle [disabled]="isDisabled"
            [title]="description">
      <span class="flex-grow-1">
        <ng-container *ngTemplateOutlet="itemFromObject; context: { $implicit: getHeadingLabel(true) }"></ng-container>
      </span>
    </button>
    <div ngbDropdownMenu>
      <ng-template ngFor let-opt [ngForOf]="items">
        <label ngbDropdownItem *ngIf="!opt.isHidden"
               class="my-0"
               [ngClass]="{ 'bg-dark text-light': isSelected(opt.value) }"
               (click)="onSelectionChange(opt.value)">
          <ng-container *ngTemplateOutlet="itemFromObject; context: { $implicit: opt }"></ng-container>
        </label>
      </ng-template>
    </div>
  </div>
</ng-template>

<!-- known item ui -->
<ng-template #itemFromObject let-item>
  <span class="text-nowrap">
    <i [ngClass]="item.iconCss" [title]="item.label"></i>
    <ng-container *ngIf="!isSmall || !item.iconCss"> {{item.label || item.value}}</ng-container>
  </span>
</ng-template>
