import { Injectable } from '@angular/core';
import { AppInsightsRef } from '@tcc/ui';
import { concat, of } from 'rxjs';
import { catchError, map, toArray } from 'rxjs/operators';
import { ClientApi, OfferBatchItem, LeaseSummary } from '../client-api.service';
import { OfferBatchResult, OrgOfferBatchItem, OfferModel } from './models';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OfferManagementService {
  env = environment;

  /** maximum offers that can be sent at once. */
  maxOffersInBatch = 50;

  constructor(private aiRef: AppInsightsRef, private clientApi: ClientApi) {

  }

  /** Executes a batch of offers, returning single observable, though they may be sent in multiple requests. */
  addOffers(offers: OrgOfferBatchItem[]) {
    const batches = this.splitOffersIntoBatches(offers);
    const calls = batches.map((x) => this.processOfferBatch(x.orgCode, x.offers));

    return concat(...calls).pipe(
      toArray(),
      map(x => ({
        isSuccess: x.every(y => y.isSuccess),
        items: Array.prototype.concat(...x.map(y => y.items))
      }) as OfferBatchResult)
    );
  }

  /** converts leases to OfferModel s*/
  mapLeasesToOffers(leases: LeaseSummary[]) {
    return (leases || [])
      .map(x => {
        const offer = { ...x, newOfferAmount: x.currentOfferAmount };
        return this.updateOfferFields(offer);
      });
  }


  /** Updates calculated fields on the offer model */
  updateOfferFields(offer: OfferModel) {
    offer.newOfferDiffFromBudget = undefined;
    offer.newOfferPctOfBudget = undefined;
    offer.newOfferDiffFromLeaseAmount = undefined;
    offer.newOfferPctOfLeaseAmount = undefined;
    if (offer.newOfferAmount != null) {
      if (offer.unitSpace && offer.unitSpace.budgetedRent) {
        offer.newOfferDiffFromBudget = offer.newOfferAmount - offer.unitSpace.budgetedRent;
        offer.newOfferPctOfBudget = offer.newOfferDiffFromBudget / offer.unitSpace.budgetedRent;
      }
      if (offer.leaseAmount) {
        offer.newOfferDiffFromLeaseAmount = offer.newOfferAmount - offer.leaseAmount;
        offer.newOfferPctOfLeaseAmount = offer.newOfferDiffFromLeaseAmount / offer.leaseAmount;
      }
    }
    offer.offerAge = (offer.currentOfferCreatedOn) ? this.timeSpanFromNow(offer.currentOfferCreatedOn) : undefined;
    return offer;
  }

  private processOfferBatch(orgCode: string, offers: OfferBatchItem[]) {
    return this.clientApi.addOffers(orgCode, this.env.userInfo.userId, offers).pipe(
      map(x => ({
        isSuccess: x.result.every(y => y.isSuccess),
        items: x.result
      }) as OfferBatchResult),
      catchError(err => {
        this.aiRef.appInsights.trackException({ error: err });
        return of({
          isSuccess: false,
          items: offers.map(x => ({ isSuccess: false, item: x }))
        } as OfferBatchResult);
      })
    );
  }

  private splitOffersIntoBatches(allOffers: OrgOfferBatchItem[]) {
    const splitBatches: { orgCode: string, offers: OfferBatchItem[] }[] = [];
    const orgMap = new Map<string, OfferBatchItem[]>();
    allOffers.forEach(x => orgMap.has(x.orgCode) ? orgMap.get(x.orgCode).push(x) : orgMap.set(x.orgCode, [x]));
    for (const [orgCode, orgOffers] of orgMap.entries()) {
      for (let i = 0; i < orgOffers.length; i += this.maxOffersInBatch) {
        const offers = orgOffers.slice(i, i + this.maxOffersInBatch);
        splitBatches.push({ orgCode, offers });
      }
    }
    return splitBatches;
  }


  /** Returns number of days from today where the hours/minutes/etc are the fractional part, or undefined if dt isn't a Date. */
  private timeSpanFromNow(dt: Date) {
    return (dt instanceof Date)
      ? (Date.now() - dt.getTime()) / (1000 * 3600 * 24)
      : undefined;
  }
}
