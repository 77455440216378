<tcc-simplePanel [size]="'full'" [options]="{respondsToMainMenu: true}">
  <div panel-header>Managers Dashboard</div>
  <div panel-body class="d-flex w-100 h-100" style="position:absolute; top:0; left:0">
    <iframe class="flex-grow-1"
            src="https://app.powerbi.com/reportEmbed?reportId=b30970ee-6b7b-4aff-be73-89d578502f13&groupId=b7b2544a-9f5d-47de-b4ea-79ef9681516a&autoAuth=true&ctid=f5c71729-7816-4984-867a-0b3a69f99ff8&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
            frameborder="0"
            allowFullScreen="true">
    </iframe>
  </div>
</tcc-simplePanel>
