import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';
import { EventInfo } from '../client-api.service';
import { EnumUi } from '../shared/enum-ui';
import { EventsService } from './events.service';


@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html'
})
export class EventListComponent {

  private static eventDateDescComparer(a: EventInfo, b: EventInfo) {
    const valueA = (a && a.createdOn) ? a.createdOn.valueOf() : -1;
    const valueB = (b && b.createdOn) ? b.createdOn.valueOf() : -1;
    return valueB - valueA;
  }

  readonly contacts$ = this.appState.leaseDetail$.pipe(map((l) => l ? l.contacts : undefined));
  readonly eventTypeUi$ = this.eventsSvc.eventTypeUi$;
  readonly events$ = this.appState.leaseDetail$.pipe(
    map((l) => l ? (l.events || []).sort(EventListComponent.eventDateDescComparer) : undefined)
  );
  readonly selectedEventId = this.appState.event$.pipe(map(x => x != null ? x.eventId : undefined));
  readonly ynmResults = EnumUi.ynmResults;

  constructor(
    private appState: AppStateService,
    private eventsSvc: EventsService
  ) { }


}
