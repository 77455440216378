import { Injectable } from '@angular/core';
import { SorterComponentChangeEvent } from '@tcc/ui';
import { BehaviorSubject } from 'rxjs';
import { AggregationType } from '../shared/aggregate-util';
import { CommandStateManager } from '../shared/command-state';
import { SortInfo, SortUtil } from '../shared/sort-util';
import { OfferBatchCommandData, OfferAgeRange, OfferModel, GroupingKey } from './models';

@Injectable({
  providedIn: 'root'
})
export class OfferManagementStateService {
  private readonly ageRangeChangeSubject = new BehaviorSubject<OfferAgeRange>({});
  private readonly aggFuncChangeSubject = new BehaviorSubject<AggregationType>('avg');
  private readonly comparisonTypeChangeSubject = new BehaviorSubject<'percent' | 'value'>('percent');
  private readonly groupByChangeSubject = new BehaviorSubject<GroupingKey>('UnitType');
  private readonly offersChangeSubject = new BehaviorSubject<OfferModel[]>([]);
  private readonly selectedOffersChangeSubject = new BehaviorSubject<OfferModel[]>([]);
  private readonly sortChangeSubject = new BehaviorSubject<SortInfo>({ sortCol: undefined, sortDir: undefined });

  readonly addOffersCommands = new CommandStateManager<OfferBatchCommandData>();

  readonly ageRange$ = this.ageRangeChangeSubject.asObservable();
  get ageRange() { return this.ageRangeChangeSubject.value; }

  readonly aggFunc$ = this.aggFuncChangeSubject.asObservable();
  get aggFunc() { return this.aggFuncChangeSubject.value; }

  readonly comparisonType$ = this.comparisonTypeChangeSubject.asObservable();
  get comparisonType() { return this.comparisonTypeChangeSubject.value; }

  readonly groupBy$ = this.groupByChangeSubject.asObservable();
  get groupBy() { return this.groupByChangeSubject.value; }

  readonly offers$ = this.offersChangeSubject.asObservable();
  get offers() { return this.offersChangeSubject.value; }

  readonly selectedOffers$ = this.selectedOffersChangeSubject.asObservable();
  get selectedOffers() { return this.selectedOffersChangeSubject.value; }

  readonly sort$ = this.sortChangeSubject.asObservable();
  get sort() { return this.sortChangeSubject.value; }

  ageRangeChanged(offerAgeRange: OfferAgeRange) {
    const prevValue = this.ageRangeChangeSubject.value;
    if (offerAgeRange.max !== prevValue.max || offerAgeRange.min !== prevValue.min) {
      this.ageRangeChangeSubject.next(offerAgeRange);
    }
  }

  aggFuncChanged(aggType: AggregationType) {
    this.aggFuncChangeSubject.next(aggType);
  }

  comparisonTypeChanged(comparisonType: 'percent' | 'value') {
    if (this.comparisonTypeChangeSubject.value !== comparisonType) {
      this.comparisonTypeChangeSubject.next(comparisonType);
    }
  }

  groupByChanged(groupBy: GroupingKey) {
    this.groupByChangeSubject.next(groupBy);
  }

  offersChanged(offers: OfferModel[]) {
    this.offersChangeSubject.next(offers || []);
  }

  selectedOffersChanged(offers: OfferModel[]) {
    this.selectedOffersChangeSubject.next(offers || []);
  }

  sortChanged(states: SorterComponentChangeEvent[]) {
    this.sortChangeSubject.next(SortUtil.handleSingleItemSortChange(states));
  }
}
