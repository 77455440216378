import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { EventInfo, LeaseDetail, Organization } from './client-api.service';

/** This is an intentionally dumb service, that just serves as routing when something changes in the application state. */
@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private commandQueueStateSubj = new BehaviorSubject<{ hasItems: boolean, hasFailure: boolean }>({ hasItems: false, hasFailure: false });
  private eventChangeSubj = new BehaviorSubject<EventInfo>(undefined);
  private leaseKeysChangeSubj = new BehaviorSubject<{ externalLeaseId: number, orgCode: string }>(undefined);
  private leaseDetailChangeSubj = new BehaviorSubject<LeaseDetail>(undefined);
  private leaseUpdateSubj = new Subject<{ externalLeaseId: number, orgCode: string }>();
  private orgChangeSubj = new BehaviorSubject<Organization>(undefined);

  private refreshSubj = new Subject();

  /** The state of the commandQueue */
  get commandQueueState() { return this.commandQueueStateSubj.value; }

  /** streams changes to the command queue. */
  readonly commandQueueState$ = this.commandQueueStateSubj.asObservable();

  /** The current event */
  get event() { return this.eventChangeSubj.value; }

  /** streams changes to the current event.  Always returns current event upon subscription. */
  readonly event$ = this.eventChangeSubj.pipe(distinctUntilChanged((a, b) => this.eventsEqual(a, b)), shareReplay(1));

  /** the current lease keys */
  get leaseKeys() { return this.leaseKeysChangeSubj.value; }

  /** streams changes to the current lease.   Always returns current lease upon subscription.  */
  readonly leaseKeys$ = this.leaseKeysChangeSubj.pipe(distinctUntilChanged((a, b) => this.leasesEqual(a, b)), shareReplay(1));

  /** the current lease */
  get leaseDetail() { return this.leaseDetailChangeSubj.value; }

  /** streams changes to the current lease.   Always returns current lease upon subscription.  */
  readonly leaseDetail$ = this.leaseDetailChangeSubj.pipe(distinctUntilChanged((a, b) => this.leaseDetailsEqual(a, b)), shareReplay(1));

  /** streams notifications that a lease has been updated */
  readonly leaseUpdate$ = this.leaseUpdateSubj.pipe(shareReplay(1));

  /** the current org */
  get org() { return this.orgChangeSubj.value; }

  /** streams changes to the current organization.   Always returns current org upon subscription.  */
  readonly org$ = this.orgChangeSubj.pipe(distinctUntilChanged((a, b) => this.orgsEqual(a, b)), shareReplay(1));

  /** fired every time a refresh has been requested. */
  readonly refresh$ = this.refreshSubj.pipe(shareReplay(1));

  /** Updates command queue state failure if it hasn't already been set. */
  commandQueueFailureOccurred() {
    if (!this.commandQueueStateSubj.value.hasFailure) {
      this.commandQueueStateSubj.next({ hasItems: this.commandQueueStateSubj.value.hasItems, hasFailure: true });
    }
  }

  /** Updates command queue state hasItems only if hasItems passed value is different */
  commandQueueHasItemsChanged(hasItems: boolean) {
    if (this.commandQueueStateSubj.value.hasItems !== hasItems) {
      this.commandQueueStateSubj.next({ hasItems, hasFailure: this.commandQueueStateSubj.value.hasFailure });
    }
  }

  /** Call to notify that the current event has changed */
  eventCurrentChanged(event: EventInfo) {
    this.nextIfChangedOrNotNull(this.eventChangeSubj, event);
  }

  /** Call to notify that the current lease has changed */
  leaseKeysChanged(leaseKeys: { externalLeaseId: number, orgCode: string }) {
    this.nextIfChangedOrNotNull(this.leaseKeysChangeSubj, leaseKeys);
  }

  /** Call to notify that the current lease's data changed */
  leaseDetailChanged(lease: LeaseDetail) {
    this.nextIfChangedOrNotNull(this.leaseDetailChangeSubj, lease);
  }

  /** Notify the application that the lease with the passed keys has been updated. */
  leaseUpdated(leaseKeys: { externalLeaseId: number, orgCode: string }) {
    this.leaseUpdateSubj.next(leaseKeys);
  }

  /** Call to notify that the current event has changed */
  orgCurrentChanged(org: Organization) {
    this.nextIfChangedOrNotNull(this.orgChangeSubj, org);
  }

  /** requests application refresh. */
  refresh() {
    this.refreshSubj.next();
  }

  /** Calls next only if the value is not null or the current subject value is not null. */
  private nextIfChangedOrNotNull<T>(subj: BehaviorSubject<T>, value: T) {
    if (value != null || subj.value != null) {
      subj.next(value);
    }
  }

  /** returns true if two dates pass strict equality or they're both not null and the results of valueOf are equal. */
  private datesEqual(a: Date, b: Date) {
    return (a === b) || (a && b && a.valueOf() === b.valueOf());
  }

  private eventsEqual(a: EventInfo, b: EventInfo) {
    return a === b || (a && b && a.eventId === b.eventId && this.datesEqual(a.updatedOn, b.updatedOn));
  }

  private leasesEqual(a: { externalLeaseId?: number }, b: { externalLeaseId?: number }) {
    return (a ? a.externalLeaseId : -1) === (b ? b.externalLeaseId : -1);
  }

  private leaseDetailsEqual(a: LeaseDetail, b: LeaseDetail) {
    return a === b || (a && b && a.externalLeaseId === b.externalLeaseId && this.datesEqual(a.latestEventCreatedOn, b.latestEventCreatedOn));
  }

  private orgsEqual(a: Organization, b: Organization) {
    return (a ? a.orgCode : '') === (b ? b.orgCode : '');
  }
}
