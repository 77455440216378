import { Injectable, APP_INITIALIZER } from '@angular/core';
import { AuthConfig } from '@tcc/ui';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigService {

    private values: Configs = new Configs();
    constructor() { }

    load = async <T>(env: T): Promise<T> => {
        try {
                const response = fetch('./assets/configs.json');
                const configs: T = await (await response).json();
                for (const key in configs) {
                if (Object.prototype.hasOwnProperty.call(configs, key)) {
                    const targetProp = env[key];
                    if (targetProp !== undefined) {
                        env[key] = configs[key];

                        switch(key) {
                            case 'apiBaseUrl':
                                this.values.apiBaseUrl = configs[key];
                                break;
                            case 'appInsightsId':
                                this.values.appInsightsId = configs[key];
                                break;                            
                            case 'production':
                                this.values.production = configs[key];
                                break;                            
                        }
                    }
                }
            }
        }
        catch (e) {}
        return env;
    };

    // Gets a value of specified property in the configuration file
    get(key: any) {
        switch(key) {
            case 'apiBaseUrl':
                return this.values.apiBaseUrl;
                break;
            case 'appInsightsId':
                return this.values.appInsightsId;
                break;            
            case 'production':
                return this.values.production;
                break;            
        }
    }

    getAuthConfig(): AuthConfig {

        const authConfig = {
            clientId: '71fdc933-9a99-46a6-87de-786df3f19c68',
            protectedResourceMap: new Map([
              [
                this.values.apiBaseUrl + '/*',
                ['48b0d4a9-ac50-44f1-910b-c22a80624031/user_impersonation']
              ]
            ])
          } as AuthConfig;

        return authConfig;
    }
}

export function configFactory(config: ConfigService) {
    return () => config.load(environment);
}

export class Configs {
    apiBaseUrl: any;
    appInsightsId: any;
    production: any;
}
