import { ChangeDetectionStrategy, Component, forwardRef, Input, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


export interface SelectorItem<T> {
  iconCss?: string;
  /** is hidden from selector drop down */
  isHidden?: boolean;
  label?: string;
  value: T;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorComponent),
      multi: true
    }
  ]
})
export class SelectorComponent implements ControlValueAccessor {
  /** Tests to see if an unknown value is a selectorItem */
  static isSelecotrItem(x: any): x is SelectorItem<any> {
    return (x && x.value);
  }

  /** Converts a value to a SelectorItem or returns the item if it already is. */
  static toSelectorItem(x: any) {
    return (this.isSelecotrItem(x)) ? x : { value: x };
  }

  // tslint:disable: member-ordering
  private _multipleSelectionsLabel: SelectorItem<any>;
  private _noSelectionLabel: SelectorItem<any>;
  private _noSelectionReadOnlyLabel: SelectorItem<any>;
  private onChange: (_: any) => void = () => { };
  private onTouch: () => void = () => { };


  /** While the model value can be a single value or multiple values, we will internally store values as an array. */
  ctrlValue: any[] = [];

  /** describes the current values for the control */
  description: string;

  isDisabled = false;

  /** If true, then multiple selections are allowed,  If false the control input is displayed. */
  @Input('multiple')
  isMultiple = false;

  /** If true, then only the icon and text is displayed,  If false the control input is displayed. */
  @Input('readonly')
  isReadOnly = false;

  /** If true, icons are not displayed.  Dafaults to false */
  @Input('small')
  isSmall = false;

  items: SelectorItem<any>[] = [];
  itemMap = new Map<any, SelectorItem<any>>();

  /* An array of items to be displayed in the selection control */
  @Input('items')
  set itemsInput(value: SelectorItem<any>[]) {
    this.items = value || [];
    this.itemMap = new Map(this.items.map(x => [x.value, x]));
  }

  @Input()
  get multipleSelectionsLabel() { return this._multipleSelectionsLabel; }
  set multipleSelectionsLabel(value: SelectorItem<any> | any) {
    this._multipleSelectionsLabel = !value ? { value: 'Multiple Selections' } : SelectorComponent.toSelectorItem(value);
  }

  @Input()
  get noSelectionLabel() { return this._noSelectionLabel; }
  set noSelectionLabel(value: SelectorItem<any> | any) {
    this._noSelectionLabel = !value ? { value: ' -- selection -- ' } : SelectorComponent.toSelectorItem(value);
  }

  @Input()
  get noSelectionReadOnlyLabel() { return this._noSelectionReadOnlyLabel; }
  set noSelectionReadOnlyLabel(value: SelectorItem<any> | any) {
    this._noSelectionReadOnlyLabel = !value ? { value: 'None' } : SelectorComponent.toSelectorItem(value);
  }

  // tslint:enable: member-ordering
  constructor(private cd: ChangeDetectorRef) {
    // initialize labels with defaults by setting the inputs to undefined;
    this.multipleSelectionsLabel = undefined;
    this.noSelectionLabel = undefined;
    this.noSelectionReadOnlyLabel = undefined;
  }

  getHeadingLabel(isForInputControl: boolean) {
    const readonlyLabel = (isForInputControl) ? this.noSelectionLabel : this.noSelectionReadOnlyLabel;
    switch (this.ctrlValue.length) {
      case 0: return readonlyLabel;
      case 1: return this.itemMap.get(this.ctrlValue[0]) || readonlyLabel;
      default: return this.multipleSelectionsLabel;
    }
  }

  isSelected(value: any) {
    return this.ctrlValue.includes(value);
  }

  /** This is called when the control attempts to change a value. */
  onSelectionChange(value: any) {
    this.onTouch();

    if (this.isMultiple) {
      const existingIndex = this.ctrlValue.indexOf(value);
      if (existingIndex === -1) {
        this.ctrlValue.push(value);
      }
      else {
        this.ctrlValue.splice(existingIndex, 1);
      }
      this.updateDescription();
      this.onChange([...this.ctrlValue]);
    }
    else if (value !== this.ctrlValue[0]) {
      // handle is a single select has changed by setting ctrlValue to a new Array with the selected value.
      this.ctrlValue = [value];
      this.updateDescription();
      this.onChange(value);
    }

  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cd.detectChanges();
  }

  /**
   * Set the value from the model.
   * The user should be smart enough to pass an array or single value based on what they set isMultiple value to.
   */
  writeValue(value: any) {
    if (this.isMultiple) {
      // copy values or initialize as an empty array.
      this.ctrlValue = (Array.isArray(value)) ? [...value] : [];
    }
    else {
      this.ctrlValue = [value];
    }
    this.updateDescription();
    this.cd.detectChanges();
  }

  private updateDescription() {
    if (this.ctrlValue.length === 0) {
      const label = (this.isReadOnly) ? this._noSelectionReadOnlyLabel : this._noSelectionLabel;
      this.description = label.label || label.value;
    }
    else {
      const values = this.ctrlValue.map(x => {
        const itemMapResult = this.itemMap.get(x);
        const item = (itemMapResult) ? itemMapResult.label || itemMapResult.value || itemMapResult : x;
        return (typeof item === 'string' || item == null) ? item as string : item.toString();
      });
      this.description = values.join(', ');
    }

  }
}
