import { Subject } from 'rxjs';

export type CommandState = 'cancelled' | 'completed' | 'executing' | 'failed' | 'queued';

export interface CommandInfo<T> {
  id: number;
  data?: T;
  message?: string;
  state: CommandState;
}

export class CommandStateManager<T> {
  /** a source for all command ids */
  private static idSource = 0;

  private commandStateChangeSubject = new Subject<{ changedCommand: CommandInfo<T>, commandQueue: CommandInfo<T>[] }>();

  /** A readonly collection of commands.  Do not modify. */
  readonly commands: CommandInfo<T>[] = [];

  /** an observable that fires after command state changes. */
  readonly commandStateChange$ = this.commandStateChangeSubject.asObservable();

  /** returns true if passed command currently being watched by commandState */
  contains(idOrCommand: number) {
    return this.get(idOrCommand) !== undefined;
  }

  /** Adds a command instance and returns the command */
  enqueue(data?: T) {
    const command: CommandInfo<T> = { id: CommandStateManager.idSource++, data, state: 'queued' };
    this.commands.push(command);
    this.commandStateChangeSubject.next({ changedCommand: command, commandQueue: this.commands });

    return command;
  }

  /**
   *  The associated command is searched for and returned along with its command name.
   *  If a command is passed it is still retrieved by id so the exact object stored is returned.
   */
  get(idOrCommand: number | CommandInfo<T>) {
    const commandId = (typeof idOrCommand === 'number') ? idOrCommand : idOrCommand.id;

    return this.commands.find(x => x.id === commandId);
  }

  /**
   * Updates a command's state to any state but queued.
   * @param idOrCommand either the command id or the actual command in this command state.
   * @param state the new state.  If cancelled, completed, or failed the command will be removed.
   * @param message if not undefined, the message of the command will be replaced.
   * @param data if not undefined, the data of the command will be replaced with this value.
   */
  setState(idOrCommand: number | CommandInfo<any>, state: Exclude<CommandState, 'queued'>, message?: string, data?: T) {
    const command = this.get(idOrCommand);
    command.state = state;
    if (message) {
      command.message = message;
    }
    if (data) {
      command.data = data;
    }
    if (state === 'cancelled' || state === 'completed' || state === 'failed') {
      this.removeCommand(command);
    }
    this.commandStateChangeSubject.next({ changedCommand: command, commandQueue: this.commands });
    return command;
  }

  private removeCommand(resolvedCommand: CommandInfo<T>) {
    const index = this.commands.indexOf(resolvedCommand);
    if (index !== -1) {
      this.commands.splice(index, 1);
    }
  }

}
