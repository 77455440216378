<ng-container *ngIf="this.state === 'ready'">
    <ng-container *ngIf="isReadOnly; else dropDown">
      <ng-container *ngTemplateOutlet="itemFromId; context: { orgCode: value }"></ng-container>
    </ng-container>
    <ng-template #dropDown>
      <ng-container [ngSwitch]="((orgs$ | async) || []).length">
        <div class="form-control-plaintext" *ngSwitchCase="0">
          <ng-container *ngIf="(orgs$ | async); else loadingLabel">{{noSelectionReadOnlyLabel}}</ng-container>
          <ng-template #loadingLabel>Loading ...</ng-template>
        </div>
        <div class="form-control-plaintext" *ngSwitchCase="1">
          <ng-container *ngTemplateOutlet="itemFromId; context: { orgCode: value }"></ng-container>
        </div>
        <select *ngSwitchDefault
        class="form-select form-select-sm"
        [disabled]="isDisabled"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)">
        <option [ngValue]="undefined">{{noSelectionLabel}}</option>
        <option *ngFor="let x of orgs$ | async" [ngValue]="x.orgCode">
          <ng-container *ngTemplateOutlet="itemFromObject; context: { $implicit: x }"></ng-container>
        </option>
      </select>
    </ng-container>
  </ng-template>
  <ng-template #itemFromId let-itemId="orgCode">
    <ng-container *ngIf="(orgMap$ | async).has(itemId); else noSelection">
      <ng-container *ngTemplateOutlet="itemFromObject; context: { $implicit: (orgMap$ | async).get(itemId) }"></ng-container>
    </ng-container>
    <ng-template #noSelection>
      {{noSelectionReadOnlyLabel}}
    </ng-template>
  </ng-template>
  <ng-template #itemFromObject let-item>
    {{item.name}}
  </ng-template>

</ng-container>