import { Directive, ElementRef, Input, Optional, Renderer2, Self, DoCheck } from '@angular/core';
import { AbstractControl, FormControlName, NgControl, NgModel } from '@angular/forms';

@Directive({
  selector: '[tccValidityClasses]'
})
export class ValidityClassesDirective implements DoCheck {
  private lastCheckResult = false;

  @Input('tccValidityClasses') cssClass: string;

  @Input('validityControl') linkedControl: AbstractControl;

  constructor(private hostElement: ElementRef,
    private renderer: Renderer2, @Optional() @Self() private ctrl?: NgControl) {

  }

  ngDoCheck() {
    const checkResult = this.checkInvalidVisibility();
    if (checkResult !== this.lastCheckResult) {
      this.lastCheckResult = checkResult;
      if (checkResult) {
        this.renderer.addClass(this.hostElement.nativeElement, this.cssClass);
      }
      else {
        this.renderer.removeClass(this.hostElement.nativeElement, this.cssClass);
      }
    }
  }

  private checkInvalidVisibility() {
    const ctrl = this.getControl();

    if (ctrl) {
      if (ctrl instanceof NgModel || ctrl instanceof FormControlName) {
        const isSubmitted = (ctrl.formDirective) ? !!ctrl.formDirective.submitted : undefined;
        return ctrl.invalid && (ctrl.dirty || isSubmitted);
      }

      return ctrl.invalid;
    }
    return false;
  }

  /** gets an instance of the Model control this directive is associated with */
  private getControl() {
    return this.linkedControl || this.ctrl;
  }

}
