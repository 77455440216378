import { Component, OnDestroy, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AppInsightsRef, SubsManager } from '@tcc/ui';
import { combineLatest } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { AppCoordinatorService } from './app-coordinator.service';
import { AppListeningService } from './app-listening.service';
import { AppStateService } from './app-state.service';
import { TransitionStateService } from './shared/transition-state.service';
import { UsersService } from './users/users.service';
import { environment } from 'src/environments/environment';
import { featureKeys } from './shared/feature-keys';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subsMgr = new SubsManager();
  env = environment;
  state: 'loading' | 'ready';

  /** streams either transitioning or ready depending on the result of transtion$ */
  readonly processingState$ = combineLatest([this.transSvc.transition$, this.appState.commandQueueState$]).pipe(
    map(([transState, commandState]) => {
      if (commandState.hasFailure) {
        return 'failed';
      }
      return (commandState.hasItems || transState.isTransitioning) ? 'processing' : 'ready';
    }),
  );

  constructor(
    private appInsightsRef: AppInsightsRef,
    private appCoordinator: AppCoordinatorService,
    private appState: AppStateService,
    private idle: Idle,
    private transSvc: TransitionStateService,
    private usersSvc: UsersService,
    private listeningSvc: AppListeningService
  ) {
    this.state = 'loading'
  }

  ngOnInit(): void {

    this.idle.setTimeout(false);
    this.idle.setIdle(1200); // go idle after 20 minutes.
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // when the user and Ai context is set then set the authenticatedId on the context to the user email
    this.subsMgr.addSub = this.usersSvc.currentUser$.pipe(
      filter(user => user && true),
      tap(user => {
        this.env.userInfo = user;
        this.appInsightsRef.appInsights.setAuthenticatedUserContext(user.email);
        this.listeningSvc.start();
        this.appCoordinator.start();
        this.state = 'ready';
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.listeningSvc.stop();
    this.appCoordinator.stop();
    this.subsMgr.onDestroy();
  }

  offersNav() {
    return this.env.userInfo.features.includes(featureKeys.offerCreation);
  }
}
