<div *ngIf="(events$ | async)" >
  <div *ngIf="(events$ | async).length == 0; else eventsTbl" class="alert alert-primary mb-0">
    There are no events to display.
  </div>
  <ng-template #eventsTbl>
    <table class="table table-sm table-borderless table-striped table-hover small mb-0">
      <thead class="thead-light">
        <tr>
          <th class="sticky-top" style="width: 1%">Type</th>
          <th class="sticky-top">Contact</th>
          <th class="sticky-top">Event Date</th>
          <th class="sticky-top" style="width: 1%">Result </th>
        </tr>
        
      </thead>
      <tbody>
        <ng-container *ngFor="let e of (events$ | async); first as first" >
          <tr [ngClass]="{'border-top': !first, 'table-warning': e.eventId === (selectedEventId | async) }"
              [routerLink]="['events', e.eventId]">
            <td class="text-center" style="width: 1%">
              <app-selector [items]="eventTypeUi$ | async"
                            [ngModel]="e.eventTypeId"
                            [small]="true"
                            [readonly]="true">
              </app-selector>
            </td>
            <!--<td><app-user [userId]="e.creatorId"></app-user></td>-->
            <!-- <td><app-contact [items]="contacts$ | async" [ngModel]="e.contactExternalId" [readonly]="true"></app-contact></td> -->
            <td>{{e.creatorName}}</td>
            <td>
              {{e.createdOn | date : 'short'}}
            </td>
            <td class="text-center" style="width: 1%">
              <app-selector [items]="ynmResults" [ngModel]="e.ynmResult" [readonly]="true" [small]="true"></app-selector>
            </td>
          </tr>
          <ng-container *ngIf="e.description">
            <tr [hidden]="true"><!-- rig to keep banding --></tr>
            <tr [routerLink]="['events', e.eventId]" [ngClass]="{'table-warning': e.eventId === (selectedEventId | async) }">
              <td colspan="4">
                <div class="bg-light py-1 px-2 border rounded-sm text-dark" style="max-height: 3em; overflow-y:auto; box-sizing: content-box">
                  <div style="white-space: pre-wrap;">{{e.description}}</div>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </ng-template>
</div>
