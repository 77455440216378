import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TccUiModule } from '@tcc/ui';
import { OrgsModule } from '../orgs/orgs.module';
import { SharedModule } from '../shared/shared.module';
import { UsersModule } from '../users/users.module';
import { ContactComponent } from './contact.component';
import { EventFormComponent } from './event-form.component';
import { EventListComponent } from './event-list.component';
import { MoveOutReasonComponent } from './move-out-reason.component';



@NgModule({
  declarations: [
    ContactComponent,
    EventFormComponent,
    EventListComponent,
    MoveOutReasonComponent
  ],
  exports: [
    ContactComponent,
    EventFormComponent,
    EventListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbPopoverModule,
    OrgsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TccUiModule,
    UsersModule
  ]
})
export class EventsModule { }
