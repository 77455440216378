import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { SubsManager } from '@tcc/ui';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { delay, filter, tap } from 'rxjs/operators';
import { LeaseStatus, YnmStatus } from '../client-api.service';
import { OrgsService } from '../orgs/orgs.service';
import { EnumUi } from '../shared/enum-ui';

export interface FilterInfo {
  /** the lease end max */
  leaseEndMax?: Date;
  /** the lease end min */
  leaseEndMin?: Date;
  /** filter on lease status */
  leaseStatus?: LeaseStatus;
  /** the org code for the currently selected organization */
  orgCode?: string;
  /** Filters unit spaces */
  unitSpaceFilter?: string;
  /** Sselected status */
  ynmStatuses?: YnmStatus[];
}

@Component({
  selector: 'app-leases-filter',
  templateUrl: './leases-filter.component.html'
})
export class LeasesFilterComponent implements AfterViewInit, OnDestroy, OnInit {
  private static readonly storageKey = 'leases.component:LEASE_FILTERS';

  private readonly subsMgr = new SubsManager();

  readonly filter: FilterInfo = {
    leaseEndMax: (() => { const x = new Date(); x.setDate(x.getDate() + 365); return x; })(),
    leaseEndMin: new Date()
  };
  readonly leaseStatuses = EnumUi.leaseStatuses;
  readonly ynmStatusItems = EnumUi.ynmStatuses;


  @Output()
  readonly filterChange = new EventEmitter<FilterInfo>();

  constructor(
    private orgsSvc: OrgsService,
    @Inject(LOCAL_STORAGE) private storageSvc: StorageService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.subsMgr.addSub = this.orgsSvc.orgLookup$.pipe(
      filter(orgMap => !!orgMap),
      delay(0), // hack fix for expression changed after checked
      tap(orgMap => {
        const storageFilters = this.storageSvc.get(LeasesFilterComponent.storageKey);
        if (storageFilters) {
          // ignore restoring lease dates.  It is convienent that they keep advancing.
          this.filter.leaseStatus = storageFilters.leaseStatus ? storageFilters.leaseStatus : this.filter.leaseStatus;
          this.filter.orgCode = orgMap.has(storageFilters.orgCode) ? storageFilters.orgCode : this.filter.orgCode;
          this.filter.ynmStatuses = storageFilters.ynmStatuses
            ? (storageFilters.ynmStatuses as YnmStatus[]).filter(x => EnumUi.ynmStatuses.some(y => y.value === x))
            : this.filter.ynmStatuses;
        }
        // make sure the current state of the filters is sent up regardless if something was loaded from localStorage.
        this.filterChanged(false);
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.subsMgr.onDestroy();
  }

  filterChanged(updateLocalStorage: boolean = true) {
    if (updateLocalStorage) {
      this.storageSvc.set(LeasesFilterComponent.storageKey, this.filter);
    }

    this.filterChange.emit({ ... this.filter });
  }
}
