/** Basic Aggregation Types supported by AggreagetUtl that can be called by execute function. */
export type AggregationType = Exclude<keyof typeof AggregateUtil, keyof typeof Object | 'sumMulti' | 'execute' | 'weightedAvg'>;

export interface AggregateOptions {
  /** if true averages will ignore blank entries.  Otherwise they will be treated as 0. */
  skipBlanks?: boolean;
}

/**
 * Performs aggregations functions on arrays.
 * Note: Keeping arrays seperate from selectors allows us to handle undefined arrays.
 * Last updated 1/8/2020
 * */
export class AggregateUtil {


  static avg(src: number[], opts: AggregateOptions = {}) {
    let sum = 0;
    let count = 0;

    for (const n of (src || [])) {
      if (n != null) {
        sum += n;
        count++;
      }
      else if (!opts.skipBlanks) {
        count++;
      }
    }
    return (count !== 0) ? sum / count : undefined;
  }

  /** executes a basic aggregation on an array of numbers */
  static execute(aggType: AggregationType, src: number[], opts?: AggregateOptions) {
    return this[aggType](src, opts);
  }

  static max(src: number[]);
  static max<TItem>(src: TItem[]) {
    const filteredSrc = (src || []).filter(x => x != null);
    return (filteredSrc && filteredSrc.length !== 0)
      ? filteredSrc.reduce((acc, cur) => acc > cur ? acc : cur)
      : undefined;
  }

  static min(src: number[]);
  static min<TItem>(src: TItem[]) {
    const filteredSrc = (src || []).filter(x => x != null);
    return (filteredSrc && filteredSrc.length !== 0)
      ? filteredSrc.reduce((acc, cur) => acc < cur ? acc : cur)
      : undefined;
  }

  static sum(src: number[]) {
    return (src || []).reduce((acc, cur) => acc + (cur == null ? 0 : cur), 0);
  }

  static sumMulti<TItem>(src: TItem[], selectors: ((x: TItem) => number)[]) {
    const sums = selectors.map(() => 0);
    let value: number;
    for (const item of src || []) {
      if (item != null) {
        for (let selectorIdx = 0; selectorIdx < selectors.length; selectorIdx++) {
          value = selectors[selectorIdx](item);
          if (value) {
            sums[selectorIdx] += value;
          }
        }
      }
    }
    return sums;
  }

  static weightedAvg(src: { value: number, weight: number }[], opts: AggregateOptions = {}) {
    let totalWeight = 0;
    let weightedSum = 0;

    for (const { value, weight } of src || []) {
      if (value != null) {
        totalWeight += weight;
        weightedSum += value * weight;
      }
      else if (!opts.skipBlanks) {
        totalWeight += weight;
      }
    }
    return weightedSum / totalWeight;
  }
}
