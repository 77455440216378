import { LowerCasePipe } from '@angular/common';

/**
 * Wow this guy is from the AngularJs days!
 * I've started deleting methods as there are more esscript equivelants.
 * Or just use the ramda equivelant.
 */
export class ArrayUtil {
  /** will match article word starting a string */
  private static startingArticleRe = /^(the|an|a)\s+/gi;


  /***********************
      Comparers
  ************************/
  /** common procedure to handle nulls, if no nulls undefined is returned */
  private static compareFuncHandleNulls<T>(a: T, b: T) {
    if (a == null) {
      return (b == null) ? 0 : -1;
    } else if (b == null) {
      return 1;
    }
    return undefined;
  }
  static comparerFuncDate(a: Date, b: Date) {
    const nullCheckResult = ArrayUtil.compareFuncHandleNulls(a, b);
    return (nullCheckResult === undefined) ? a.valueOf() - b.valueOf() : nullCheckResult;
  }

  /**
   * uses the types of a and b to run the appropriate comparison
   */
  static comparerSmart(a: any, b: any) {
    const nullCheckResult = ArrayUtil.compareFuncHandleNulls(a, b);
    if (nullCheckResult !== undefined) {
      return nullCheckResult;
    }
    if (a instanceof Date) {
      a = a.valueOf();
    }
    if (b instanceof Date) {
      b = b.valueOf();
    }
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }
    return (a < b) ? -1 : ((a > b) ? 1 : 0);
  }

  /** pass as a generic sort function for an array of strings or other comparable object */
  static comparerFuncGeneric<T>(a: T, b: T) {
    const nullCheckResult = ArrayUtil.compareFuncHandleNulls(a, b);
    // tslint:disable-next-line: triple-equals
    return (nullCheckResult === undefined) ? ((a == b) ? 0 : ((a < b) ? -1 : 1)) : nullCheckResult;
  }

  /** pass as array.sort for array of numbers */
  static comparerFuncNumbers(a: number, b: number) {
    return a - b;
  }

  /**
   * creates a comparer that will sort strings
   * @param opts
   */
  static compareStringsFuncFactory(opts: { ignoreCase?: boolean, ignoreArticles?: boolean }) {
    const transformer = ArrayUtil.compareStringsFuncFactoryBuildStringTransformer(opts);

    return (a: string, b: string) => {
      a = transformer(a);
      b = transformer(b);

      return (a < b) ? -1 : ((a > b) ? 1 : 0);
    };
  }



  static compareSelectorStringsFuncFactory<T>(selector: (x: T) => string, opts: { ignoreCase?: boolean, ignoreArticles?: boolean }) {
    const transformer = ArrayUtil.compareStringsFuncFactoryBuildStringTransformer(opts);

    return (a: T, b: T) => {
      const aText = transformer((a != null) ? selector(a) : '');
      const bText = transformer((b != null) ? selector(b) : '');
      return (aText < bText) ? -1 : ((aText > bText) ? 1 : 0);
    };
  }

  private static compareStringsFuncFactoryBuildStringTransformer(opts: { ignoreCase?: boolean, ignoreArticles?: boolean } = {}) {
    function ensureNonNull(x: string) { return (x == null) ? '' : x; }
    function ignoreCaseTrans(x: string) { return x.toLowerCase(); }
    function ignoreArticlesTrans(x: string) { return x.replace(ArrayUtil.startingArticleRe, ''); }

    return (opts.ignoreCase)
      ? (opts.ignoreArticles)
        ? (x: string) => ignoreArticlesTrans(ignoreCaseTrans(ensureNonNull(x)))
        : (x: string) => ignoreCaseTrans(ensureNonNull(x))
      : (opts.ignoreArticles)
        ? (x: string) => ignoreArticlesTrans(ensureNonNull(x))
        : ensureNonNull;
  }

  /** sorts array by string key, returning new array */
  static sortByString<T>(ary: T[], sortKeyFunc: (item: T) => string, opts?: { ignoreCase?: boolean, ignoreArticles?: boolean }) {
    if (!ary) {
      return ary;
    }

    const sortResults: { value: T, sortKey: string }[] = [];
    const lcasePipe = new LowerCasePipe();
    let sortKey: string;
    for (const value of ary) {
      sortKey = sortKeyFunc(value);
      if (sortKey == null) {
        sortKey = '';
      }
      if ((opts || {}).ignoreCase) {
        sortKey = lcasePipe.transform(sortKey);
      }
      if ((opts || {}).ignoreArticles) {
        sortKey = sortKey.replace(ArrayUtil.startingArticleRe, '');
      }
      sortResults.push({ value, sortKey });
    }

    return sortResults.sort((a, b) => a.sortKey.localeCompare(b.sortKey)).map(x => x.value);
  }

}

