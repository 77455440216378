import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate } from '@angular/router';
import { AppStateService } from '../app-state.service';
import { EventInfo } from '../client-api.service';
import { AppRouteParamsService } from '../shared/app-route-params.service';

/** Loads and Unloads Events during Activation and Deactivation. */
@Injectable({
  providedIn: 'root'
})
export class EventGuard implements CanActivate, CanDeactivate<any> {

  constructor(private appState: AppStateService, private appRouteParamsSvc: AppRouteParamsService) { }

  canActivate(next: ActivatedRouteSnapshot) {

    const eventId = this.appRouteParamsSvc.getEventId(next);
    let event: EventInfo;

    if (eventId === 'new') {
      event = {};
    }
    else if (eventId != null) {
      event = this.appState.leaseDetail && this.appState.leaseDetail.events.find(x => x.eventId === eventId);
    }

    if (event != null) {
      this.appState.eventCurrentChanged(event);
      return true;
    }
    else {
      this.appState.eventCurrentChanged(undefined);
      return false;
    }
  }

  canDeactivate() {
    this.appState.eventCurrentChanged(undefined);
    return true;
  }
}
