<ng-container *ngIf="isReadOnly; else dropDown">
  <ng-container *ngIf="!value; else hasItem">Unknown Reason</ng-container>
  <ng-template #hasItem>
    <ng-container *ngIf="!isItemsLoaded; else itemsLoaded">Loading ...</ng-container>
  </ng-template>
  <ng-template #itemsLoaded>
    <ng-container *ngIf="itemMap.has(value); else notFound">{{itemMap.get(value).name}}</ng-container>
  </ng-template>
  <ng-template #notFound>
    Contact Not Found
  </ng-template>
</ng-container>
<ng-template #dropDown>
  <select class="form-select form-select-sm" [ngModel]="value" (ngModelChange)="onValueChange($event)">
    <option [ngValue]="undefined"> -- select one -- </option>
    <option *ngFor="let opt of itemsVisible" [ngValue]="opt.reasonId">{{opt.name}}</option>
  </select>
</ng-template>
