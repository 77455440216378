import { ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { MoveOutReason } from '../client-api.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-move-out-reason',
  templateUrl: './move-out-reason.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoveOutReasonComponent),
      multi: true
    }
  ]
})
export class MoveOutReasonComponent implements ControlValueAccessor {
  // tslint:disable: member-ordering
  private onChange: (_: any) => void = () => { };
  private onTouch: () => void = () => { };

  itemMap = new Map<number, MoveOutReason>();
  itemsVisible: MoveOutReason[] = [];

  /** This will update the values of contacts, contactMap, and possibly value */
  @Input('items')
  set itemsSource(value: MoveOutReason[]) {
    this.isItemsLoaded = value != null;
    const items = (Array.isArray(value)) ? value : [];
    this.itemsVisible = items.filter(x => !x.isHidden).sort((a, b) => a.sortIndex - b.sortIndex);
    this.itemMap = new Map(items.map(x => [x.reasonId, x]));
    if (!this.itemMap.has(this.value)) {
      this.onValueChange(this.value);
    }
  }


  isDisabled = false;
  isItemsLoaded = false;

  @Input('readonly')
  isReadOnly = false;

  value: any;

  // tslint:enable: member-ordering
  constructor(private cd: ChangeDetectorRef) { }

  onValueChange(value: any) {
    if (value !== this.value) {
      this.value = value;
      this.onChange(value);
      this.onTouch();
    }
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cd.detectChanges();
  }

  writeValue(value: any) {
    this.value = (value == null) ? undefined : value;
    this.cd.detectChanges();
  }
}
