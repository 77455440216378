import { MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

/**
 * Rxjs operator that performs a retry, delaying either by a fixed interval or the result of a function.
 */
export function retryDelay<T>(retryAttempts: number, delayMsOrFunc: (attemptIndex: number) => number | number)
  : MonoTypeOperatorFunction<T> {

  const delayFunc = (typeof delayMsOrFunc === 'number')
    ? (_) => delayMsOrFunc
    : delayMsOrFunc;

  return (source$: Observable<T>) => {
    return source$.pipe(
      retryWhen((err) =>
        err.pipe(
          concatMap((e, i) => (i >= retryAttempts) ? throwError(e) : of(e).pipe(delay(delayFunc(i))))
        )
      )
    );
  };
}
