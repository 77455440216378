import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, SubsManager } from '@tcc/ui';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';
import { EventInfo, YnmResult } from '../client-api.service';
import { EnumUi } from '../shared/enum-ui';
import { tapError } from '../shared/tap-error-operator';
import { UsersService } from '../users/users.service';
import { EventsService } from './events.service';

interface EventForm extends FormGroup {
  controls: {
    contactExternalId: AbstractControl,
    description: AbstractControl,
    eventTypeId: AbstractControl,
    moveOutReasonId: AbstractControl,
    ynmResult: AbstractControl
  };
}

type EventFormState = 'loading' | 'ready';
type EventFormMode = 'add' | 'edit' | 'view' | 'unknown';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html'
})
export class EventFormComponent implements OnInit, OnDestroy {

  private eventDeleteSubject = new Subject();
  private eventSubmitSubject = new Subject();
  private eventFormSubmittedSubject = new BehaviorSubject<('loading' | 'ready')>('ready');

  private subsMgr = new SubsManager();

  readonly eventTypeUi$ = this.eventsSvc.eventTypeUi$;
  readonly event$ = this.appState.event$;
  readonly lease$ = this.appState.leaseDetail$;
  readonly moveOutReasons$ = this.eventsSvc.moveOutReason$;
  readonly org$ = this.appState.org$;
  readonly user$ = this.usersSvc.currentUser$;
  readonly userMap$ = this.usersSvc.userMap$;

  readonly pageInfo$ = combineLatest([this.event$, this.lease$]).pipe(
    map(([evt, l]) => {
      if (!evt || !l || !evt.eventId) {
        return { isVisible: false };
      }
      const eventIndex = l.events.findIndex(x => x.eventId === evt.eventId);
      const nextEventId = eventIndex > 0 ? l.events[eventIndex - 1].eventId : undefined;
      const prevEventId = eventIndex !== -1 && eventIndex < l.events.length - 1 ? l.events[eventIndex + 1].eventId : undefined;
      return { isVisible: true, nextEventId, prevEventId };
    })
  );

  readonly state$ = combineLatest([
    this.event$, 
    this.eventTypeUi$, 
    this.lease$, 
    this.moveOutReasons$, 
    this.org$, 
    this.user$, 
    this.userMap$,
    this.eventFormSubmittedSubject.pipe(map(x => x !== 'loading'))
  ]).pipe(
    map(([event, eventTypeUi, lease, moveOurReasons, org, user, userMap, eventFormSubmission]) =>
      (event && eventTypeUi && lease && moveOurReasons && org && user && userMap && eventFormSubmission) ? 'ready' : 'loading'
    ),
    startWith<EventFormState>('loading'),
    distinctUntilChanged()
  );


  readonly mode$ = combineLatest([this.state$, this.event$, this.user$]).pipe(
    map(([s, evt, usr]) => {
      if (s === 'ready' && evt && usr) {
        return ((!evt.eventId) ? 'add' : 'view') as EventFormMode;
      }
      return 'unknown' as EventFormMode;
    }),
    distinctUntilChanged(),
    startWith<EventFormMode>('unknown')
  );

  readonly isExisting$ = this.mode$.pipe(map(x => x === 'edit' || x === 'view'));
  readonly isReadOnly$ = this.mode$.pipe(map(x => x !== 'add' && x !== 'edit'));

  readonly ynmResults = EnumUi.ynmResults;

  eventForm = this.fb.group({
    contactExternalId: [undefined, Validators.required],
    description: [],
    eventTypeId: [undefined, Validators.required],
    moveOutReasonId: [undefined, Validators.required],
    ynmResult: [undefined, Validators.required]
  }) as EventForm;

  constructor(
    private appState: AppStateService,
    private eventsSvc: EventsService,
    private fb: FormBuilder,
    private notifySvc: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private usersSvc: UsersService
  ) { }

  ngOnInit(): void {
    // this is the best way to do required conditional validity - by enabling and disabling the field when its dependcy's value changes.
    this.subsMgr.addSub = this.eventForm.controls.ynmResult.valueChanges.pipe(
      startWith(this.eventForm.controls.ynmResult.value),
      map((x: YnmResult) => x === YnmResult.No),
      filter((enabled) => enabled !== this.eventForm.controls.moveOutReasonId.enabled),
      tap((enabled) => (enabled) ? this.eventForm.controls.moveOutReasonId.enable() : this.eventForm.controls.moveOutReasonId.disable())
    ).subscribe();

    this.subsMgr.addSub = this.eventSubmitSubject.pipe(
      tap(() => this.eventFormSubmittedSubject.next('loading')),
      switchMap(() => combineLatest([this.event$, this.lease$, this.org$])),
      map(([evtOrig, l, o]) => ({ l, o, evt: { eventId: evtOrig.eventId, ...this.mapFormGroupToEventInfo() } })),
      take(1),
      switchMap(({ l, o, evt }) => this.eventsSvc.upsertEvent(o.orgCode, l.externalLeaseId, evt)),
      tap(() => {
        this.notifySvc.addSuccess('Event Saved succesfully.');
        this.closeComponent();
      }),
      tap(() => this.eventFormSubmittedSubject.next('ready')),
      tapError(() => {
        this.notifySvc.addError('Failed saving event.  Please try again later.');
        this.closeComponent();
      })
    ).subscribe();

    this.subsMgr.addSub = this.event$.pipe(tap(x => this.mapEventInfoToFormGroup(x))).subscribe();
  }
  ngOnDestroy(): void {
    this.subsMgr.onDestroy();
  }

  closeComponent() {
    this.router.navigate(['./'], { relativeTo: this.route.parent });
  }

  deleteEvent() {
    this.eventDeleteSubject.next();
  }
  submitEvent() {
    if (this.eventForm.valid) {
      this.eventSubmitSubject.next();
    }
    else {
      this.notifySvc.addError('There are errors in the form.  Please check and try again.');
    }
  }

  private mapEventInfoToFormGroup(eventInfo: EventInfo) {
    this.eventForm.reset();
    if (eventInfo) {
      const ctrls = this.eventForm.controls;
      ctrls.contactExternalId.setValue(eventInfo.contactExternalId);
      ctrls.description.setValue(eventInfo.description);
      ctrls.eventTypeId.setValue(eventInfo.eventTypeId);
      ctrls.moveOutReasonId.setValue(eventInfo.moveOutReasonId);
      ctrls.ynmResult.setValue(eventInfo.ynmResult);
    }
  }

  private mapFormGroupToEventInfo() {
    const ctrls = this.eventForm.controls;
    const eventInfo: EventInfo = {
      contactExternalId: (ctrls.contactExternalId.value != null) ? parseInt(ctrls.contactExternalId.value, 10) : undefined,
      description: (ctrls.description.value != null) ? ctrls.description.value.toString() : undefined,
      eventTypeId: (ctrls.eventTypeId.value != null) ? parseInt(ctrls.eventTypeId.value, 10) : undefined,
      moveOutReasonId: (ctrls.moveOutReasonId.enabled && ctrls.moveOutReasonId.value != null)
        ? parseInt(ctrls.moveOutReasonId.value, 10)
        : undefined,
      ynmResult: (ctrls.ynmResult.value != null) ? ctrls.ynmResult.value as YnmResult : undefined
    };
    return eventInfo;
  }
}
