import { ScrollingModule } from '@angular/cdk/scrolling';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleModule } from '@ng-idle/core';
import { AppInsightsModule, AppInsightsTelemtryItem, AuthConfig, AuthModule, AUTH_CONFIG, IGNORE_CANCELED_HTTP_REQUEST_AI_TELEM_INITIALIZER, TccUiModule, TitleRouteSyncerModule } from '@tcc/ui';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { API_BASE_URL, ClientApi } from './client-api.service';
import { DashboardHostComponent } from './dashboard-host/dashboard-host.component';
import { EventsModule } from './events/events.module';
import { LeasesModule } from './leases/leases.module';
import { OfferManagementModule } from './offer-management/offer-management.module';
import { OrgsModule } from './orgs/orgs.module';
import { SharedModule } from './shared/shared.module';
import { UsersModule } from './users/users.module';
import { ConfigService } from './config.Service';

export function aiTelemInitializer(env: AppInsightsTelemtryItem) {
  env.tags['ai.cloud.role'] = 'Tcc.Ynm.App';
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardHostComponent,
  ],
  imports: [
    /** Angular Modules */
    BrowserModule,
    BrowserAnimationsModule,
    EventsModule,
    FormsModule,
    /** Third Party modules */
    AppInsightsModule.forRoot(
      { instrumentationKey: environment.appInsightsId },
      [aiTelemInitializer, IGNORE_CANCELED_HTTP_REQUEST_AI_TELEM_INITIALIZER]
    ),
    AuthModule.forRoot(),
    NgIdleModule.forRoot(),
    ScrollingModule,
    TccUiModule.forRoot(),
    TitleRouteSyncerModule.forRoot({ defaultTitle: 'YNM', titleReplacementStrategy: 'defaultAndCurrent' }),
    /** Application Modules */
    AppRoutingModule,
    LeasesModule,
    OfferManagementModule,
    OrgsModule,
    SharedModule,
    UsersModule,
    // Not yet, I'm adraid
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      deps: [ConfigService],
      useFactory: (configService: ConfigService) => () => configService.load(environment),
      multi: true,
    },
    {
      provide: API_BASE_URL,
      deps: [ConfigService, APP_INITIALIZER],
      useFactory: (configService: ConfigService) =>
        configService?.get('apiBaseUrl'),
    },
    {
      provide: AUTH_CONFIG,
      deps: [ConfigService, APP_INITIALIZER],
      useFactory: (configService: ConfigService) =>
        configService?.getAuthConfig()
    },
    ClientApi,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
